<template>
    <v-container>
        <v-card>
            <v-card-title class="mb-12">
                Rendimentos Unidade de Obra
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                label="Search by unit name or code"
                single-line
                hide-details
            ></v-text-field>
            <v-btn @click="searchField" color="success" small class="mt-4 mr-4"><v-icon>mdi-magnify</v-icon></v-btn>
            </v-card-title>
            <v-card-text class="mb-n12 elevation-0 rounded-xl primary white--text" v-if="fixedTable.length > 0">
                <v-data-table
                    :headers="headers"
                    :items="fixedTable"
                    :server-items-length="total"
                    :loading="loading"
                    :footer-props="tableFooter"
                    :options.sync="options"
                    hide-default-footer
                    hide-default-header
                    class="mt-12 transparent white--text datatable-remove-on-hover"
                    locale="pt-pt"
                >
                <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr>
                            <th v-for="h in headers" :key="h.text" :class="h.class">
                            <span class="white--text">{{h.text}}</span>
                            </th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:item.performance="{ item }">
                    {{ item.performance }}
                </template>

                <template v-slot:item.lastMonth.performance="{ item }">
                    {{ item.lastMonth.performance }}
                </template>

                <template v-slot:item.atualYear.atualYear="{ item }">
                    {{ item.atualYear.atualYear }}
                </template>

                <template v-slot:item.lastYear.lastYear="{ item }">
                    {{ item.lastYear.lastYear }} 
                </template>

                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning">
                    Your search for "{{ search }}" found no results.
                    </v-alert>
                </template>

                <template v-slot:item.options="{ item }">
                    <Tooltip
                        class="my-4 mr-4"
                        color="error"
                        text="Remover da tabela"
                        top
                    >
                        <v-btn @click="deleteFixedItem(item)" class="white--text" color="error" small><v-icon small dark>mdi-trash-can</v-icon></v-btn>
                    </Tooltip>
                </template>
                </v-data-table>
            </v-card-text>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :search="search"
                    :items="items"
                    :server-items-length="total"
                    :loading="loading"
                    :footer-props="tableFooter"
                    :options.sync="options"
                    class="mt-12"
                    locale="pt-pt"
                >

                <template v-slot:item.performance="{ item }">
                    {{ item.performance }}
                </template>

                <template v-slot:item.lastMonth.performance="{ item }">
                    {{ item.lastMonth.performance }}
                </template>

                <template v-slot:item.atualYear.atualYear="{ item }">
                    {{ item.atualYear.atualYear }}
                </template>

                <template v-slot:item.lastYear.lastYear="{ item }">
                    {{ item.lastYear.lastYear }} 
                </template>

                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning">
                    Your search for "{{ search }}" found no results.
                    </v-alert>
                </template>

                <template v-slot:item.options="{ item }">
                    <Tooltip
                        class="my-4 mr-4"
                        color="primary"
                        text="Fixar pesquisa"
                        top
                        v-if="toFixVisible(item)"
                    >
                        <v-btn @click="fixedItem(item)" color="primary" small><v-icon small>mdi-auto-fix</v-icon></v-btn>
                    </Tooltip>
                </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
  </template>
<style lang="scss">  
    .datatable-remove-on-hover{
        tbody {
            tr:hover {
                background-color: transparent !important;
            }
        }
    }
</style>
  
  <script>
    import Dashboard from "@/api/Dashboard.js";
    import Tooltip from '@/components/ui/Tooltip.vue';

    export default {
        components:{
            Tooltip
        },
        data(){
            return{
                fixedTable: [],
                search: '',
                items: [],
                options: {},
                total: 0,
                loading: false,
                headers: [
                {
                        text: 'Unidade de obra',
                        value: 'unitObject.name',
                        width: "40%",
                    },
                    {
                        text: 'Código',
                        value: 'unitObject.code',
                        width: "10%",
                    },
                    {
                        text: 'Unidade',
                        value: 'unitObject.unit',
                        width: "5%",
                    },
                    {
                        text: 'Mes atual',
                        sortable: true,
                        value: 'performance',
                        width: "10%",
                    },
                    { 
                        text: 'Mes anterior', 
                        sortable: true,
                        value: 'lastMonth' ,
                        width: "10%",
                    },
                    { 
                        text: 'Ano atual', 
                        sortable: true,
                        value: 'atualYear.atualYear' ,
                        width: "10%",
                    },
                    { 
                        text: 'Ano anterior', 
                        sortable: true,
                        value: 'lastYear.lastYear',
                        width: "10%",
                    },
                    {
                        text: 'Opções',
                        sortable: false,
                        value: 'options',
                        width: "5%",
                    }
                ],
                tableFooter: {
                    'items-per-page-options': [5, 10, 15, 20],
                    'items-per-page-text': "Linhas por página"
                },
            }
        },
        watch: {
            'options': {
                immediate: false,
                handler () {
                    setTimeout(() => {this.searchUnit()}, 10);
                },
                deep: true,
                },
        },
        async beforeMount() {
            this.loading = true
            

            await this.searchUnit()

            
            this.loading = false
        },
        methods:{
            toFixVisible(i){
                let exists = false
                this.fixedTable.forEach(element => {
                    if(element.id == i.id)
                        exists = true
                });
                return !exists
            },
            deleteFixedItem(ob){
                let position = -1;
                let i = 0
                this.fixedTable.forEach(element => {
                    if(element.id == ob.id)
                        position = i
                    
                    i++
                });

                if(position != -1)
                    this.fixedTable.splice(position, 1)
            },
            fixedItem(i){
                this.fixedTable.push(i)
            },
            async searchField(){
                await this.searchUnit()
            },
            async searchUnit(event){
                if(!!event && event.type == 'submit'){
                    this.options.page = 1;
                }

                let filter = {...this.filter};

                let request = {
                    sortBy: this.options.sortBy,
                    sortDesc: this.options.sortDesc,
                    page: this.options.page,
                    limit: this.options.itemsPerPage,
                    search: this.search
                };
                Object.assign(request, filter);

                localStorage["rendimentosMO-" + this.$root.session.id] = JSON.stringify({
                    filter: this.filter,
                    options: this.options,
                });


                await Dashboard.unit(request).then(({data}) => {
                    this.items = data.data
                    this.total = data.total
                    console.log(this.items)
                });
            },
        },
        computed:{
        }
  }
          
  
  </script>