<template>
  <div>
    <v-tabs background-color="primary" dark :class="!work ? 'd-none' : ''" v-model="tab" fixed-tabs>
      <v-tab>Detalhe</v-tab>
      <v-tab>Localização</v-tab>
      <v-tab>Subempreiteiros</v-tab>
      <v-tab>Partes Diárias</v-tab>
      <v-tab>Template</v-tab>
      <v-tab>Performance</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" touchless>
      <v-row v-if="method == 'create'">
        <v-col cols="12" md="6">
          <v-autocomplete
              v-model="copyWork"
              prepend-inner-icon="mdi-content-copy" 
              :label="'Copiar obra'"
              :items="diariesWorks"
              :item-text="item => item.code + ' - ' + item.name"
              item-value="id"
              hide-selected
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="auto">
          <v-btn
              :disabled="isCopyWorkNull"
              @click="copyWorkFunction()"
              color="success">
              <v-icon class="mr-2">mdi-content-copy</v-icon>Copiar
            </v-btn>
        </v-col>
      </v-row>
      <v-tab-item class="mt-6" :key="1">
        <Photos ref="ref_fotos" :work_object="fields" @reload="$emit('update-object')"/>
        <validation-observer ref="form" v-slot="{ invalid }">
          <v-form @submit.prevent="submit" class="mb-16">
            <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
              ></v-progress-linear>
            <fieldset :disabled="loading">
              <v-row>
                <v-col cols="12" md="4">
                  <validation-provider v-slot="{ errors }" vid="code" name="Código" rules="required">
                    <v-text-field
                      v-model="fields.code"
                      prepend-inner-icon="mdi-barcode"
                      label="Código *"
                      dense outlined
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="2">
                  <validation-provider v-slot="{ errors }" vid="work_value" name="Valor">
                    <v-text-field
                        v-model="fields.work_value"
                        prepend-inner-icon="mdi-currency-eur"
                        label="Valor obra"
                        dense outlined
                        clearable
                        :error-messages="errors"
                      ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                    v-model="started_at"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider v-slot="{ errors }" vid="started_at" name="Data Inicio" rules="required">
                        <v-text-field
                          label="Data Inicio"
                          v-model="fields.started_at"
                          dense outlined
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="fields.started_at = null"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="fields.started_at"
                      locale="pt-pt"
                      @input="started_at = false;"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                    v-model="finished_at"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider v-slot="{ errors }" vid="finished_at" name="Data Fim">
                        <v-text-field
                          label="Data Fim"
                          v-model="fields.finished_at"
                          prepend-inner-icon="mdi-calendar"
                          dense outlined
                          readonly
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          :disabled="fields.started_at.length == 0"
                          @click:clear="fields.finished_at = null"
                          v-if="fields.started_at != null"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="fields.finished_at"
                      locale="pt-pt"
                      @input="finished_at = false;"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="required">
                    <v-text-field
                      v-model="fields.name"
                      prepend-inner-icon="mdi-form-textbox"
                      dense outlined
                      label="Nome *"
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-if="$root.session.hasPermission(['super', 'clients.read'])"
                    v-model="fields.id_client"
                    :items="clients"
                    dense outlined
                    :item-text="item => item.name"
                    item-value="id"
                    menu-props="auto"
                    label="Clientes *"
                    prepend-inner-icon="mdi-factory"
                    clearable
                  ></v-select>
                  <v-text-field :value="fields.client.name" disabled v-else></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider v-slot="{ errors }" vid="status" name="Estado" rules="required">
                    <v-select
                        v-model="fields.status"
                        :items="status"
                        :item-text="item => item.name"
                        dense outlined
                        item-value="id"
                        menu-props="auto"
                        label="Estado *"
                        prepend-inner-icon="mdi-cog"
                        :error-messages="errors"
                        clearable
                      ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                      v-model="fields.description"
                      label="Descrição"
                      dense outlined
                      prepend-inner-icon="mdi-card-text"
                      counter="600"
                      maxlength="600"
                      clearable
                    ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-card>
                    <v-card-title class="primary white--text">
                      Frentes de obra
                    </v-card-title>
                    <v-card-text>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Código
                              </th>
                              <th class="text-left">
                                Nome
                              </th>
                              <th class="text-left">
                                Op.
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in fields.work_fronts"
                              :key="item.id"
                            >
                              <td>
                                <validation-provider v-slot="{ errors }" vid="Referência" name="Referência" rules="|">
                                  <v-text-field
                                    class="mt-2"
                                    v-model="item.reference"
                                    :error-messages="errors"
                                    dense outlined
                                    label="Referência"
                                  />
                                </validation-provider>
                              </td>
                              <td>
                                <validation-provider v-slot="{ errors }" vid="Descrição" name="Descrição" rules="required">
                                  <v-text-field
                                    class="mt-2"
                                    v-model="item.description"
                                    :error-messages="errors"
                                    dense outlined
                                    label="Descrição *"
                                  />
                                </validation-provider>
                              </td>
                              <td>
                                <IconRemove
                                  @on-submit="deleteWorkFront(item)"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-btn @click="addWorkFront" class="success">add</v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
                    <v-col cols="12" md="4">
                      <v-card outlined>
                        <v-card-title class="primary white--text">Encarregados *</v-card-title>
                        <v-card-text>                          
                          <v-row>
                            <v-col cols="12" md="10">
                              <v-select
                                class="mt-2"
                                v-model="fields.commissioner_id"
                                :items="commissioners"
                                :item-text="item => 'Nome: ' + item.name + ' - Username: '+ item.username"
                                item-value="id"
                                menu-props="auto"
                                dense outlined
                                label="Encarregado"
                                prepend-inner-icon="mdi-account-hard-hat"
                                clearable
                              ></v-select>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-btn rounded class="mt-3" small color="error" @click="addComissioner">Add</v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-text>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">
                                    Nome
                                  </th>
                                  <th class="text-left">
                                    Frentes
                                  </th>
                                  <th class="text-left">
                                    Opções
                                  </th>
                                </tr>
                              </thead>
                              <tbody :key="reloadWorkFrontUser">
                                <tr
                                  v-for="comm in fields.commissioners"
                                  :key="comm.id"
                                >
                                  <td>{{ comm.name }}</td>
                                  <td>
                                    {{getFrentes(comm)}}
                                  </td>
                                  <td>
                                    <Tooltip
                                      class="my-4 mr-4"
                                      color="success"
                                      style="z-index: 9999;"
                                      text="Associar frentes obra"
                                      top
                                    >
                                      <v-btn @click="addWorkFrontToUser(comm)" icon>
                                        <v-icon color="success">
                                          mdi-account-hard-hat
                                        </v-icon>
                                      </v-btn>
                                    </Tooltip>
                                    <IconRemove
                                        class="mr-2"
                                        @on-submit="deleteCommissioner(comm)"
                                      />
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-card outlined>
                        <v-card-title class="primary white--text">Engenheiros *</v-card-title>
                        <v-card-text>
                          <v-row>                            
                            <v-col cols="12" md="10" class="mt-2">
                              <v-select
                                  v-model="fields.engineer_id"
                                  :items="engineers"
                                  :item-text="item => 'Nome: ' + item.name + ' - Username: '+ item.username"
                                  item-value="id"
                                  dense outlined
                                  menu-props="auto"
                                  label="Engenheiro"
                                  prepend-inner-icon="mdi-account-tie"
                                  clearable
                              ></v-select>
                            </v-col>                    
                            <v-col cols="12" md="2">
                              <v-btn rounded class="mt-3" small color="error" @click="addEngineer">Add</v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-text>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">
                                    Nome
                                  </th>
                                  <th class="text-left">
                                    Opções
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="comm in fields.engineers"
                                  :key="comm.id"
                                >
                                  <td>{{ comm.name }}</td>
                                  <td>
                                    <IconRemove
                                        class="mr-2"
                                        @on-submit="deleteEngineer(comm)"
                                      />
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
              <v-row class="mt-8">
                <v-expansion-panels focusable multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-header disable-icon-rotate>
                      <h3>
                        <v-icon color="primary">mdi-calendar-alert</v-icon> <span>Suspensões da obra</span>
                      </h3>
                      <template v-slot:actions>
                        <p>
                          <v-icon color="error">
                            mdi-sigma
                          </v-icon>
                          {{fields.suspended_dates.length}}
                        </p>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table dense>
                            <template v-slot:default>
                              <thead>                                
                                <tr>
                                  <th class="text-left" width="20%">
                                    Período de
                                  </th>
                                  <th class="text-left" width="20%">
                                    até
                                  </th>   
                                  <th class="text-center">
                                    Motivo
                                  </th>                                  
                                  <th width="10%" class="text-center">
                                    Opções
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(element, index) in fields.suspended_dates" v-bind:key="index + 20000">
                                  <td>{{element.from}}</td>
                                  <td>{{element.to}}</td>
                                  <td>{{element.description}}</td>
                                  <td>
                                    <IconRemove
                                      class="mr-2"
                                      @on-submit="deleteSuspendedDate(element)"                                      
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          
                          <v-dialog
                            v-model="addSuspendedDate"
                            width="500"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="red lighten-2 mt-8"
                                dark
                                style="display: block; margin: auto;"
                                v-bind="attrs"
                                v-on="on"
                              >
                                Adicionar nova data
                              </v-btn>
                            </template>

                            <v-card>
                              <v-card-title class="text-h5 primary white--text">
                                Adicionar data de suspensão
                              </v-card-title>

                              <v-card-text>
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-menu
                                      v-model="addSuspendedDateFrom"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                          v-model="newSuspendedDate.from"
                                          label="De *"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="newSuspendedDate.from"
                                        @input="addSuspendedDateFrom = false"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-menu
                                      v-model="addSuspendedDateTo"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                          v-model="newSuspendedDate.to"
                                          label="até *"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="newSuspendedDate.to"
                                        @input="addSuspendedDateTo = false"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                  <v-col cols="12" md="12">
                                    <v-textarea v-model="newSuspendedDate.description" label="Motivo de suspensão"></v-textarea>
                                  </v-col>
                                </v-row>
                              </v-card-text>

                              <v-divider></v-divider>

                              <v-card-actions>
                                <v-btn
                                  color="primary"
                                  @click="addNewSuspendedDate"
                                >
                                  Adicionar
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="error"
                                  @click="addSuspendedDate = false"
                                >
                                  Fechar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>

              <!-- Mão de Obra-->
              <v-row class="mt-8">
                <v-expansion-panels focusable multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-header disable-icon-rotate>
                      <h3>
                        <v-icon color="primary">mdi-account-hard-hat</v-icon> <span>Mão de Obra</span>
                      </h3>
                      <template v-slot:actions>
                        <p v-if="fields.workers != null">
                          <v-icon color="error">
                            mdi-sigma
                          </v-icon>
                          {{fields.workers.length}}
                        </p>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <Workers :fields="fields" return-object :method="method" @update="$emit('update-object')"/>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>

              <!-- Máquinas-->
              <v-row class="mt-8">
                <v-expansion-panels focusable multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-header disable-icon-rotate>
                      <h3>
                        <v-icon color="primary">mdi-bulldozer</v-icon> <span>Máquinas</span>
                      </h3>
                      <template v-slot:actions>
                        <p v-if="fields.machines != null">
                          <v-icon color="error">
                            mdi-sigma
                          </v-icon>
                          {{fields.machines.length}}
                        </p>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <Machines :fields="fields" @update="$emit('update-object')"/>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>

              <v-row class="mt-8">
                <v-expansion-panels focusable multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-header disable-icon-rotate>
                      <h3>
                        <v-icon color="primary">mdi-clipboard-list-outline</v-icon> <span>Unidades de Obra</span>
                      </h3>
                      <template v-slot:actions>
                        <p>
                          <v-icon color="error">
                            mdi-sigma
                          </v-icon>
                          {{fields.work_unit_list.length}}
                        </p>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="9" xs="9" sm="9" md="12">
                          <!-- DESKTOP VERSION -->
                          <v-simple-table dense class="d-none d-lg-block">
                            <template v-slot:default>
                              <thead>
                                
                                <tr>
                                  <th class="text-center">
                                    Código U.O.
                                  </th>
                                  <th class="text-center">
                                    Nome
                                  </th>
                                  <th class="text-center">
                                    Qtd. Orçamentada
                                  </th>
                                  <th class="text-center">
                                    Unidade
                                  </th>
                                  <th class="text-center">
                                    Rendimento Orçamentado
                                  </th>
                                  <th class="text-center">
                                    Equipa
                                  </th>
                                  <th class="text-center">
                                    Subempreiteiro
                                  </th>
                                  <th class="text-center">
                                    Performance
                                  </th>
                                  <th class="text-center">
                                    Opções
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(element, index) in fields.work_unit_list" v-bind:key="index + 10000">
                                  <td class="text-center">
                                      <label>{{element.code}}</label>
                                  </td>
                                  <td class="text-center">
                                      <label>{{element.unit}}</label>
                                  </td>
                                  <td class="text-center">
                                      <label>{{element.qty_budgeted}}</label>
                                  </td>
                                  <td class="text-center">
                                      <label>{{element.unit_measure}}</label>
                                  </td>
                                  <td class="text-center">
                                      <label>{{element.budgeted_income}}</label>
                                  </td>
                                  <td class="text-center">
                                      <label>{{element.team}}</label>
                                  </td>
                                  <td class="text-center">
                                      <label>{{element.subcontractor == null ? '-' : element.subcontractor}}</label>
                                  </td>
                                  <td class="text-center">
                                    <v-switch 
                                      inset
                                      color="success"
                                      v-model="element.enable_performance"
                                    />                                      
                                  </td>
                                  <td class="text-center">
                                    <v-row>
                                      <v-col cols="12" md="3">

                                        <IconView
                                          @click="viewListWorkUnit(element)"                                      
                                        />
                                      </v-col>
                                      <v-col cols="12" md="3">
                                        <IconRemove
                                          v-if="$root.session.hasPermission(['super']) || $root.session.hasRole(['ceo', 'dto'])"
                                          class="mr-2"
                                          @on-submit="deleteWorkUnitList(element.id)"                                      
                                        />

                                      </v-col>
                                    </v-row>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <!-- DESKTOP VERSION -->

                          <!-- MOBILE VERSION -->
                          <v-simple-table dense class="d-sm-none">
                            <template v-slot:default>                              
                              <tbody>
                                <tr v-for="element in workUnitList" v-bind:key="element.id">
                                  <td class="text-overline mt-10">
                                      <v-row>
                                        <v-col cols="12">
                                          <p class="mt-6 font-weight-black">Código: <small>{{element.object.code}}</small></p>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="12">
                                          <p class="mt-n6 font-weight-black">Nome: <small>{{ element.name }}</small></p>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="12">
                                          <p class="mt-n6 font-weight-black">Qtd. Orç.: <small>{{ element.qty_budgeted }}</small></p>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="12">
                                          <p class="mt-n6 font-weight-black">Unidade: <small>{{ element.object.unit }}</small></p>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="12">
                                          <p class="mt-n6 font-weight-black">Rend. Orç.: <small>{{ element.budgeted_income }}</small></p>
                                        </v-col>
                                      </v-row>
                                      <v-row class="mb-6">
                                        <v-col cols="12">
                                          <p class="mt-n6 font-weight-black">Equipa: <small>{{ element.team }}</small></p>
                                        </v-col>
                                      </v-row>
                                      <v-row class="mb-6">
                                        <v-col cols="12">
                                          <p class="mt-n6 font-weight-black">Performance: <v-switch 
                                              inset
                                              color="success"
                                              v-model="element.enable_performance"
                                            />   
                                          </p>
                                        </v-col>
                                      </v-row>
                                      <v-row class="mb-6">
                                        <IconView
                                          class="mr-2"
                                          @click="viewListWorkUnit(element)"                                      
                                        />
                                        <IconRemove
                                          v-if="$root.session.hasPermission(['super']) || $root.session.hasRole(['ceo', 'dto'])"
                                          class="mr-2"
                                          @on-submit="deleteWorkUnitList(element.id)"                                      
                                        />
                                      </v-row>
                                  </td>                          
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <!-- MOBILE VERSION -->                          
                          <v-row justify="center">
                          <v-btn
                            fab
                            dark
                            small
                            color="primary"
                            class="mt-7"
                            @click="addNewUnit()"
                          >
                            <v-icon small>mdi-plus</v-icon>
                          </v-btn>
                    </v-row>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="fields.unmarkedUnits.length > 0 && method=='update'">
                    <v-expansion-panel-header disable-icon-rotate>
                      <h3>
                        <v-icon color="error">
                            mdi-alert-circle
                        </v-icon> <span class="error--text">Em falta</span>
                      </h3>
                      <template v-slot:actions>
                        <p>
                          <v-icon color="error">
                            mdi-sigma
                          </v-icon>
                          {{fields.unmarkedUnits.length}}
                        </p>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="9" xs="9" sm="9" md="12">
                          <!-- DESKTOP VERSION -->
                          <v-simple-table dense class="d-none d-lg-block">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-center">
                                    Código U.O.
                                  </th>
                                  <th class="text-center">
                                    Nome
                                  </th>
                                  <th class="text-center">
                                    Código diária
                                  </th>
                                  <th class="text-center">
                                    Data diária
                                  </th>
                                  <th class="text-center">
                                    Opções
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(element, index) in fields.unmarkedUnits" v-bind:key="index + 8599">
                                  <td class="text-center">
                                      <label>{{element.unit_code}}</label>
                                  </td>
                                  <td class="text-center">
                                      <label>{{element.name}}</label>
                                  </td>
                                  <td class="text-center">
                                      <div v-for="(d, index) in element.diaries" :key="index + 10599">
                                        <label style="cursor: pointer;" @click="openDiary(element.diaries[index])" class="text-decoration-underline">{{d.diary_code}}</label>
                                      </div>
                                  </td>
                                  <td class="text-center">
                                    <div v-for="(d, index) in element.diaries" :key="index + 11599">
                                      <label>{{d.date}}</label>
                                    </div>
                                  </td>
                                  <td class="text-center">
                                    <Tooltip
                                      class="my-4 mr-4"
                                      color="primary"
                                      text="Transferir unidade de obra para a lista"
                                      top
                                    >
                                      <v-btn outlined x-small @click="transferUnit(element)">
                                        <v-icon>mdi-transfer</v-icon>
                                      </v-btn>
                                    </Tooltip>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <!-- DESKTOP VERSION -->

                          <!-- MOBILE VERSION -->
                          <v-simple-table dense class="d-sm-none">
                            <template v-slot:default>                              
                              <tbody>
                                <tr v-for="(element, index) in fields.unmarkedUnits" v-bind:key="index + 9599">
                                  <td class="text-overline mt-10">
                                      <v-row>
                                        <v-col cols="12">
                                          <p class="mt-6 font-weight-black">Código U.O.: <small>{{element.unit_code}}</small></p>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="12">
                                          <p class="mt-n6 font-weight-black">Nome: <small>{{ element.name }}</small></p>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="12">
                                          <p class="mt-n6 font-weight-black">Código diária: <small>{{ element.diary_code }}</small></p>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="12">
                                          <p class="mt-n6 font-weight-black">Data diária: <small>{{ element.date }}</small></p>
                                        </v-col>
                                      </v-row>
                                      <v-row class="mb-6">
                                        <Tooltip
                                          class="my-4 mr-4"
                                          color="primary"
                                          text="Transferir unidade de obra para a lista"
                                          top
                                        >
                                          <v-btn outlined x-small @click="transferUnit(element)">
                                            <v-icon>mdi-transfer</v-icon>
                                          </v-btn>
                                        </Tooltip>
                                      </v-row>
                                  </td>                          
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <!-- MOBILE VERSION -->     
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
              
              <v-row class="mt-6" align="center" justify="space-around">
                <v-btn :disabled="invalid" depressed color="primary" type="submit">
                  Gravar
                </v-btn>
              </v-row>
            </fieldset>
          </v-form>
          
          <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Obra gravada com sucesso!
          </DialogSuccess>
        </validation-observer>
      </v-tab-item>
      <v-tab-item class="mt-6" :key="2">
        <Localization :satellite="true" :methereology="fields.methereology" :workLocal="fields.local" :id="fields.id" :work_object="fields"/>
      </v-tab-item>
      <v-tab-item class="mt-6" :key="3">
        <Subcontractors />
      </v-tab-item>
      <v-tab-item class="mt-6" :key="4">
        <v-data-table
          :headers="diariesHeaders"
          :items="diariesItems"
          :server-items-length="diariesTotal"
          :loading="diariesLoading"
          :footer-props="diariesTableFooter"
          :options.sync="diariesOptions"
          locale="pt-pt"
        >
          <template v-slot:item.work="{ item }">
            {{diariesWorks != null ? diariesWorks.find(i => i.id == item.work_id).code + " : " + diariesWorks.find(i => i.id == item.work_id).name : "-"}}
          </template>

          <template v-slot:item.status="{ item }">
            {{diariesStatus != null ? diariesStatus.find(i => i.id == item.status).name : "-"}}
          </template>

          <template v-slot:item.date="{ item }">
            {{getTableDate(item.date)}}
          </template>
          

          <template v-slot:item.actions="{item}">
            <v-row>
              <v-col cols="12" md="auto">
                <Tooltip
                  color="success"
                  text="Pré-visualização de diária"
                  top
                >
                  <v-btn icon color="success" @click="previewDiary.id = item.id; previewDiary.show=true;">
                    <v-icon small>mdi-magnify</v-icon>
                  </v-btn>
                </Tooltip>
              </v-col>  
              <v-col cols="12" md="auto">
                <IconView
                  class="mr-2"
                  @click="viewDiary(item)"
                />
              </v-col>
              <v-col cols="12" md="auto">
                <v-icon
                  v-for="approval of getPossibleApprovals(item)"
                  v-bind:key="approval"
                  small
                  color="success"
                  @click="confirmApprove(item, approval)"
                >
                  mdi-check
                </v-icon>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item class="mt-6 mb-6" :key="5">
        <v-progress-linear 
          v-if="loading"
          indeterminate
        ></v-progress-linear>
        <div v-else>
        <small>Defina um template a ser usado em todas as Partes Diárias relacionadas com esta obra</small>
        <TemplateMaoObra class="mt-6" :key="reloadPage"
          :template_fields="template_fields"
          :workers="workers"
          return-object
          @remove="removeMaoObra"
        />
        <TemplateMaquina :key="reloadPage"
          :template_fields="template_fields"
          :machines="machines"
          return-object
          @remove="removeMaquina"
        />
        <TemplateUnidades :key="reloadPage"
          :template_fields="template_fields"
          :units="units"
          return-object
          @remove="removeUnidade"
        />
        <v-row class="mt-8" align="center" justify="space-around">
          <v-btn depressed color="primary" @click="saveTemplate">
            Gravar
          </v-btn>
        </v-row>
      </div>
      </v-tab-item>
      <v-tab-item class="mt-6" :key="7">
        <ShowPerformance :general_work_performance="general_work_performance" :workUnitList="workUnitList" :fields="fields" :diaryParts="diariesItems"/>
      </v-tab-item>
    </v-tabs-items>
    <ErrorSnackbar v-model="error.show" :message="error.message"/>

    <!-- ******************************* -->
      <!-- ******************************* -->
      <!-- ADICIONAR UNIDADE DE OBRA MODAL -->
      <!-- ******************************* -->
      <!-- ******************************* -->
      <v-dialog
        v-model="showWorkList"
        persistent
        max-width="800px">
      <v-card>
            <fieldset>
              <v-card-title class="primary white--text">
                      Adicionar Unidade Obra
              </v-card-title>
              <v-card-text>
                  <v-row class="mt-4">
                      <v-col cols="12">                    
                        <v-autocomplete
                            v-model="newWorkUnit.work_id"
                            label="Unidade Obra *"
                            :items="units"
                            :item-text="item => item.name"
                            item-value="id"
                            hide-selected
                            clearable
                            >
                        </v-autocomplete>     
                      </v-col>   
                      <v-col cols="12" md="4">  
                              <v-autocomplete                                              
                                      v-model="newWorkUnit.work_id"
                                      label="Código *"
                                      :items="units"
                                      :item-text="item => item.code"
                                      item-value="id"
                                      hide-selected
                                      clearable
                                      >
                              </v-autocomplete>     
                      </v-col>
                      <v-col cols="12" md="4">
                          <v-autocomplete
                                  v-model="newWorkUnit.work_id"
                                  label="Unidade"
                                  :items="units"
                                  :disabled="true"
                                  :item-text="item => item.unit"
                                  item-value="id"
                                  hide-selected
                                  clearable
                                  >
                              </v-autocomplete>   
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-switch
                          v-model="newWorkUnit.enable_performance"
                          label="Calcular performance"
                          inset color="success"
                          /> 
                      </v-col>
                      <v-divider class="mt-4 mb-4"></v-divider>
                  </v-row>
                  <v-row>
                      <v-col cols="12" md="4">
                              <v-text-field
                                  v-model="newWorkUnit.qty_budgeted"
                                  prepend-inner-icon="mdi-form-textbox"
                                  label="Qtd. Orç."
                                  clearable
                              ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                              <v-text-field
                                  v-model="newWorkUnit.budgeted_income"
                                  small
                                  prepend-inner-icon="mdi-google-circles-communities"
                                  label="Rend. Orç."
                                  clearable
                              >
                                  
                              <template v-slot:append>        
                                  <v-icon>{{ newWorkUnit.unit_measure }}</v-icon> 
                              </template>
                              </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                              <v-text-field
                                  v-model="newWorkUnit.team"
                                  prepend-inner-icon="mdi-counter"
                                  label="Equipa"
                                  clearable
                              ></v-text-field>
                      </v-col>              
                      <v-col cols="12" md="4">
                        <v-autocomplete
                          :items="subcontractors"
                          v-model="newWorkUnit.subcontractor_id"
                          item-text="name"
                          item-value="id"
                          clearable
                          label="Subempreiteiros"
                        />
                      </v-col>
                  </v-row>
              </v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="error"
                  depressed
                  @click="closeWorkList"
              >
              Cancelar
              </v-btn>
              <v-btn :disabled="newWorkUnit.qty_budgeted == null || newWorkUnit.work_id == null ||newWorkUnit.budgeted_income == null" depressed color="primary" @click="addWorkUnitToWork">
                  Gravar
              </v-btn>            
            </v-card-actions>
            </fieldset>
      </v-card>
    </v-dialog>
      <SuccessSnackBar v-model="successSnack.show" :message="successSnack.message"/>
      <ExtensiveSuccessSnackBar :timeout="5000" v-model="successSnack.showExtensive" :message="successSnack.message"/>

      <v-dialog
        v-model="previewDiary.show"
        max-width="900px">
        <PreviewDiary v-if="previewDiary.show" @close="previewDiary.show=false" :id="previewDiary.id"/>
      </v-dialog>  

      <v-dialog
        v-model="addFrontUser.show"
        max-width="900px">
        <v-container fluid class="ma-0 pa-0">
          <v-card>
            <v-card-title class="primary white--text">
              Associar frentes a: {{addFrontUser.user != null ? addFrontUser.user.name : ''}}
            </v-card-title>
            <v-card-text>
              <v-row class="mt-3">
                <v-col cols="12" md="10">
                  <v-autocomplete
                    dense outlined
                    :items="fields.work_fronts"
                    :item-text="item => item.description"
                    label="Frente Obra"
                    item-value="id"
                    v-model="addFrontUser.frente"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn @click="associarFrente" class="success">
                    Associar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Código
                      </th>
                      <th class="text-left">
                        Descrição
                      </th>
                      <th class="text-left">
                        Op.
                      </th>
                    </tr>
                  </thead>
                  <tbody :key="reloadModalWorkFrontUser">
                    <tr
                      v-for="item in addFrontUser.user.fronts"
                      :key="item.id"
                    >
                      <td>
                        {{item.reference}}
                      </td>
                      <td>
                        {{item.description}}
                      </td>
                      <td>                        
                        <IconRemove
                            @on-submit="removeWorkFrontFromUser(item)"
                          />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-container>
      </v-dialog>

  </div>
</template>
<script>
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import Unit from "@/api/Unit"
import Worker from "@/api/Worker"
import Machine from "@/api/Machine"
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import IconView from '@/components/ui/IconView.vue';
import Work from "@/api/Work.js";
import User from "@/api/User.js";
import Templates from "@/api/Templates.js";
import Diary from "@/api/Diary.js";
import Company from '@/api/Company.js'
import dayjs from 'dayjs'
import TemplateMaoObra from '@/components/works/templates/TemplateMaoObra'
import TemplateMaquina from '@/components/works/templates/TemplateMaquina'
import TemplateUnidades from '@/components/works/templates/TemplateUnidades'
import Client from "@/api/Client"
//import WorkUnitList from '@/components/works/dialogs/WorkUnitList'
//import APIWorkUnitList from "@/api/WorkUnitList"
import IconRemove from '@/components/ui/IconRemove.vue';
import ShowPerformance from '@/components/works/performance/ShowPerformance.vue';
import Tooltip from '@/components/ui/Tooltip.vue';
//import Performance from "@/api/Performance"
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';
import ExtensiveSuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';
import Localization from '@/components/works/Localization.vue'
import Workers from '@/components/works/Workers.vue'
import Machines from '@/components/works/Machines.vue'
import Subcontractors from '@/components/works/Subcontractors.vue'

import PreviewDiary from '@/components/diaries/PreviewDiary'

import Photos from '@/components/works/photos/Photos.vue'



export default {
  components: {
    ErrorSnackbar,
    Photos,
    IconView,
    DialogSuccess,
    TemplateMaoObra,
    TemplateMaquina,
    TemplateUnidades,
    //WorkUnitList,
    IconRemove,
    ShowPerformance,
    Tooltip,
    SuccessSnackBar,
    ExtensiveSuccessSnackBar,
    PreviewDiary,
    Localization,
    Workers,
    Machines,
    Subcontractors
  },
  props: {
   work: Object,
   method: String,
   disabled: Boolean,
 },
 watch: { 
  'diariesOptions': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchDiaries()}, 10);
      },
      deep: true,
    },
    work: {
      immediate: true, 
      async handler (val) {
        if(!val) {
          let parent = this
          if(this.$root.session.hasPermission(['super', 'clients.read']))
            await Client.list().then(({data}) => {
                parent.clients = data;
            });
          return;
        }
        this.loading = true

        let data = {...val};

        this.fields = data;

       
        
        let parent = this
        
        /*await Performance.getPerformance(this.$route.params.id)
        .then(({data}) => {
          parent.general_work_performance = data
        });*/
        if(this.$root.session.hasPermission(['super', 'clients.read']))
          await Client.list().then(({data}) => {
              parent.clients = data;
          });

        if(this.fields.client != null)
          this.clients.forEach(a1 => {
            if(a1.id == parent.fields.client.id)
              parent.fields.id_client = parent.fields.client.id
          })
          
        await Worker.list().then(({data}) => {
            parent.workers = data;
        });
        await Machine.list().then(({data}) => {
            parent.machines = data;
        });
        
        if(data.template != null){
          this.template_fields.workers = data.template.workers
          this.template_fields.workers.forEach(a1 => {
            parent.workers.forEach(a2 => {
              if(a1.worker_id == a2.id)
                a1.object = a2
            });
          });
          this.template_fields.machines = data.template.machines
          this.template_fields.machines.forEach(a1 => {
            parent.machines.forEach(a2 => {
              if(a1.machine_id == a2.id)
                a1.object = a2
            });
            
          });
          this.template_fields.units = data.template.units
          this.template_fields.units.forEach(a1 => {
            parent.units.forEach(a2 => {
              if(a1.unit_id == a2.id)
                a1.object = a2
            });
          });
        }
        
        if(this.fields.started_at.length > 0){
          this.fields.started_at = dayjs(this.fields.started_at).format("YYYY-MM-DD");
        }
        
        if(this.fields.finished_at != null)
          if(this.fields.finished_at.length > 0){
            this.fields.finished_at = dayjs(this.fields.finished_at).format("YYYY-MM-DD");
          }

        this.fields.id = data.id;

        await this.getWorkUnitsList()      

        await this.searchDiaries()
        this.loading = false

      
        console.log(this.fields)
      }
    },
  },
  data: () => ({
    previewDiary: {
      show: false,
      id: null
    },
    successSnack:{
      show: false,
      message: '',
      showExtensive: false
    },
    addSuspendedDateFrom: null,
    addSuspendedDateTo: null,
    newSuspendedDate:{
      from: null,
      to: null,
      description: null
    },
    addSuspendedDate: false,
    copyWorkActive: false,
    newWorkUnit:{
      id: null,
      work_id: null,
      start_date: null,
      code: null,
      team: null,
      unit: null,
      qty_budgeted: null,
      budgeted_income: null,
      edit: false,
      enable_performance: true,
      work_unit_id: null,
      has_subcontractor: false,
      subcontractor_id: null
    },
    transferID: null,
    copyWork: null,
    response:{
      id: null
    },
    reloadPage:0,
    general_work_performance: null,
    editableWorkUnit: null,
    showWorkList: false,
    workUnitList:[],
    clients: [],
    units: [],
    machines: [],
    workers: [],
    methodTemplate: 'create',
    fields: {
      id: null,
      code: dayjs().unix(),
      work_fronts: [],
      local: null,
      name: null,
      status:null,
      description:null,
      started_at:null,
      finished_at:null,
      commissioner_id: null,
      overseer_id: null,
      engineer_id: null,
      id_client: null,
      workUnitList: [],
      unmarkedUnits:[],
      commissioners: [],
      engineers: [],
      work_unit_list: [],
      suspended_dates: [],
      workers: [],
      machines: []
    },
    template_fields:{
      workers: [],
      machines: [],
      units: []
    },
    loading:false,
    error: {
      title: '',
      message: '',
      show: false
    },
    diariesHeaders: [
      {
        text: 'Código',
        sortable: true,
        value: 'code',
      },
      {
        text: 'Obra',
        sortable: true,
        value: 'work',
      },
      {
        text: 'Estado',
        sortable: true,
        value: 'status',
      },
      {
        text: 'Data',
        sortable: true,
        value: 'date',
      },
      { 
        text: 'Opções', 
        value: 'actions', 
        sortable: false 
      },
    ],
    diariesItems: [],
    diariesTotal: 0,
    diariesLoading: false,
    diariesTableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    diariesOptions: {},
    status: [],
    success: false,
    started_at:false,
    checkApproval:{
      showModal: false,
      item: null,
      aprroval: null
    },
    finished_at:false,
    commissioners:[],
    overseers:[],
    engineers:[],
    subcontractors:[],
    tab: 0,
    diariesStatus: [],
    diariesWorks: [],
    filter: {
      work_id: null,
    },
    addFrontUser:{
      show: false,
      user: null,
      frente: null
    },
    reloadWorkFrontUser: 324234,
    reloadModalWorkFrontUser:796213
  }),
  mounted(){
    this.fillBaseData();
  },
  methods:{
    removeWorkFrontFromUser(item){
      let ob = null
      let eng = false
      let posicao_pessoa = -1;
      let valor = 0;
      this.fields.commissioners.forEach(element => {
        if(element.id == this.addFrontUser.user.id){
          ob = element
          posicao_pessoa = valor
        }
        valor++
      });

      if(posicao_pessoa == -1){
        valor = 0
        this.fields.engineers.forEach(element => {
          if(element.id == this.addFrontUser.user.id){
            ob = element
            posicao_pessoa = valor
          }
          valor++
        });
        eng = true
      }

      let pos = 0
      let i = 0
      ob.fronts.forEach(element => {
        if(element.id == item.id)
          pos = i

          i++
      });

      if(!eng)
        this.fields.commissioners[posicao_pessoa].fronts.splice(pos, 1)
      else
        this.fields.engineers[posicao_pessoa].fronts.splice(pos, 1)
    },
    getFrentes(item){
      let ar = []
      
      item.fronts.forEach(element => {
        ar.push(element.description)
      });

      return ar.toString()
    },
    associarFrente(){
      if(this.addFrontUser.frente == null)
        return

      let objectFront = null
      this.fields.work_fronts.forEach(element => {
        if(element.id == this.addFrontUser.frente)
          objectFront = element
      });


      
      this.fields.commissioners.forEach(element => {
        if(element.id == this.addFrontUser.user.id){
          element.fronts.push(objectFront)
        }
      });

      this.fields.engineers.forEach(element => {
        if(element.id == this.addFrontUser.user.id){
          element.fronts.push(objectFront)
        }
      });

      this.reloadWorkFrontUser++
      this.reloadModalWorkFrontUser++
    },
    addWorkFrontToUser(item){
      this.addFrontUser.show = true
      this.addFrontUser.user = item
    },
    deleteWorkFront(item){
      let pos = -1
      let i = 0
      this.fields.work_fronts.forEach(element => {
        if(element.id == item.id)
          pos = i

          i++
      });

      this.fields.work_fronts.splice(pos, 1)
    },
    addWorkFront(){
      this.fields.work_fronts.push({
        id: 'new-' + dayjs().unix(),
        reference: null,
        description: null
      });
    },
    closeWorkList(){
      this.newWorkUnit = {
        id: null,
        work_id: null,
        start_date: null,
        code: null,
        team: null,
        unit: null,
        qty_budgeted: null,
        budgeted_income: null,
        edit: false,
        enable_performance: true,
        work_unit_id: null,
        has_subcontractor: false,
        subcontractor_id: null
      }
      this.showWorkList = false
    },
    confirmApprove(item, approval){
      this.checkApproval.item = item
      this.checkApproval.approval = approval

      this.checkApproval.showModal = true
    },
    async approve(){
      let item = this.checkApproval.item
      let approval = this.checkApproval.approval
      let parent = this
      await Diary.approve(item.id, approval)
        .then(() => {
          this.searchDiaries();
          parent.checkApproval.showModal = false

          this.approved = true;

        });
    },
    getPossibleApprovals(item){
      
      let approvals = [];

      for(let approval in item.approvals){

        let auxApproval = item.approvals[approval];

        if(this.$root.session.hasRole(["super", approval]) && auxApproval.approved_at == null){

          approvals.push(approval);
        }
      }

      return approvals;
    },
    changedCheckboxSub(v){
      this.newWorkUnit.has_subcontractor = v
    },
    addNewUnit(){
      this.newWorkUnit = {
        id: null,
        work_id: null,
        start_date: null,
        code: null,
        team: null,
        unit: null,
        qty_budgeted: null,
        budgeted_income: null,
        edit: false,
        enable_performance: true,
        work_unit_id: null,
        has_subcontractor: false,
        subcontractor_id: null
      }
      
      this.showWorkList = false;
      this.showWorkList = true;
    },
    deleteSuspendedDate(ob){
      let index = -1
      let i = 0
      this.fields.suspended_dates.forEach(element => {
        if(element.id == ob.id)
          index = i

        i++
      });

      if(index == -1)
        return

      this.fields.suspended_dates.splice(index, 1)

      this.successSnack.message = 'Data de Suspensão retirada'
      this.successSnack.show = true
    },
    addNewSuspendedDate(){
      if(this.newSuspendedDate == null)
        return
      if(this.newSuspendedDate.from == null)
        return
      if(this.newSuspendedDate.to == null)
        return

      this.fields.suspended_dates.push({
        from: this.newSuspendedDate.from,
        to: this.newSuspendedDate.to,
        description: this.newSuspendedDate.description
      });

      this.successSnack.message = 'Data de Suspensão adicionada'
      this.successSnack.show = true

      this.newSuspendedDate.from = null
      this.newSuspendedDate.to = null
      this.newSuspendedDate.description = null
    },
    deleteCommissioner(c){
      let i = 0
      let pos = 0
      this.fields.commissioners.forEach(element => {
        if(element.id == c.id)
          pos = i

        i++
      });

      this.fields.commissioners.splice(pos, 1)
    },
    deleteEngineer(e){
      let i = 0
      let pos = 0
      this.fields.engineers.forEach(element => {
        if(e.id == element.id)
          pos = i

        i++
      });

      this.fields.engineers.splice(pos, 1)
    },
    addComissioner(){
      this.commissioners.forEach(element => {
        if(element.id == this.fields.commissioner_id)
          this.fields.commissioners.push(element)
      });
      this.fields.commissioners[this.fields.commissioners.length - 1].fronts = []      
    },
    addEngineer(){
      this.engineers.forEach(element => {
        if(element.id == this.fields.engineer_id)
          this.fields.engineers.push(element)
      });
    },
    openDiary(element){
      window.open('/admin/diaries/' + element.diary_id, '_blank')
    },
    transferUnit(unit){
      this.transferID = unit.unit_id
      this.newWorkUnit.work_id = unit.unit_id
      this.showWorkList = true
    },
    async copyWorkFunction(){
      this.copyWorkActive = true
      this.loading = true
      let code = this.fields.code
      await Work.find(this.copyWork).then(({data}) => { 
        this.fields = data; 
        this.fields.finished_at = null
        this.fields.status = null
        this.fields.client = null
        this.fields.name = null
        this.fields.code = code
        this.fields.id = null

        this.fields.suspended_dates = []

        
        this.loading = false;
      });

      this.workUnitList = []
      /*await APIWorkUnitList.get(this.copyWork).then((data) => {

      let info = data.data
      info.forEach(element => {        
        this.workUnitList.push({
          id: element.id,
          name: element.object.name,
          qty_budgeted: element.qty_budgeted,
          unit: element.object.unit,
          budgeted_income: element.budgeted_income,
          team:element.team,
          object: element.object
        })
      });
      });*/
      this.loading = false
    },
    closeWorkUnitModal(){
      this.showWorkList = false; 
      this.editableWorkUnit = null;
      this.showWorkList = false;
    },
    viewListWorkUnit(item){
      this.newWorkUnit = item
      
      if(item.subcontractor_id != null)
        this.newWorkUnit.has_subcontractor = true
      else this.newWorkUnit.has_subcontractor = false
        
      this.newWorkUnit.edit = true

      if(item.work_unit_id != null){
        this.chooseWorkUnitDate = true
        this.fields.work_unit_list.forEach(element => {
          if(element.id == item.work_unit_id)
            this.newWorkUnit.work_unit_id = element.id_work_unit
        });
      }else{
        this.chooseWorkUnitDate = false
        this.newWorkUnit.work_unit_id = null
      } 

      this.showWorkList = true
    },
    deleteWorkUnitList(id){
      let i = 0
      let pos = 0
      let objectRemoved = null
      this.fields.work_unit_list.forEach(element => {
        if(element.id == id){
          pos = i
          objectRemoved = element
        }
        
        i++
      });

      this.fields.work_unit_list.splice(pos, 1)

      // Validar dependencias

      // Verificar se existe alguém que depende deste objeto
      this.fields.work_unit_list.forEach(element => {
        if(element.work_unit_id == objectRemoved.id_work_unit){
          element.work_unit_id = objectRemoved.work_unit_id
        }
      });
      this.analyzeDependencies()

    },
    async getWorkUnitsList(){
      /*this.workUnitList = []
      await APIWorkUnitList.get(this.$route.params.id).then((data) => {

      let info = data.data
      info.forEach(element => {        
        this.workUnitList.push({
          id: element.id,
          name: element.object.name,
          qty_budgeted: element.qty_budgeted,
          unit: element.object.unit,
          budgeted_income: element.budgeted_income,
          team:element.team,
          object: element.object
        })
      });
      });*/
    },
    cleanWorkUnit(){
      this.newWorkUnit = {
        id: null,
        enable_performance: true,
        work_id: null,
        code: null,
        team: null,
        unit: null,
        qty_budgeted: null,
        budgeted_income: null,
        edit: false,
      }
    },
    async addWorkUnitToWork(){
      let unit = ''
      let unit_measure = ''
      let code = ''

      this.units.forEach(element => {
        if(element.id == this.newWorkUnit.work_id){
          unit = element.name
          unit_measure = element.unit
          code = element.code
        }
      });

      if(this.chooseWorkUnitDate == false)
        this.newWorkUnit.work_unit_id = null

        let s_object = null
        this.subcontractors.forEach(element => {
          if(element.id == this.newWorkUnit.subcontractor_id)
            s_object = element        
        });

      

      if(this.newWorkUnit.edit){
        this.newWorkUnit.code = code
        this.newWorkUnit.unit = unit
        this.newWorkUnit.measure = unit_measure
        this.newWorkUnit.edit = false
        this.newWorkUnit.subcontractor = s_object == null ? null : s_object.name;
        this.showWorkList = false
        
      
       
        this.cleanWorkUnit()

        if(this.method == 'update'){
          this.submit()
        }
        return
      }

  
      this.fields.work_unit_list.push({
        id: 'new_' + this.fields.work_unit_list.length,
        work_id: this.newWorkUnit.work_id,
        id_work_unit: this.newWorkUnit.work_id,
        unit: unit,
        code: code,
        enable_performance: this.newWorkUnit.enable_performance,
        unit_measure: unit_measure,
        team: this.newWorkUnit.team,
        qty_budgeted: this.newWorkUnit.qty_budgeted,
        budgeted_income: this.newWorkUnit.budgeted_income,
        start_date: this.newWorkUnit.start_date,
        work_unit_id: this.newWorkUnit.work_unit_id,
        subcontractor: s_object == null ? null : s_object.name,
        subcontractor_id: this.newWorkUnit.subcontractor_id
      })

      let i = 0
      let pos = -1
      this.fields.unmarkedUnits.forEach(element => {
        if(element.unit_code == code)
          pos = i

        i++
      });

      if(pos != -1){
        this.fields.unmarkedUnits.splice(pos, 1)
        this.successSnack.message = 'Unidade de Obra transferida com sucesso'
        this.successSnack.showExtensive = true
      }

      this.editableWorkUnit = null


      this.cleanWorkUnit()

      if(this.method == 'update'){
        this.submit()
      }
      this.showWorkList = false
      //await this.getWorkUnitsList()
    },
    removeMaoObra(line_id){
      if(this.fields.template == null){
        let index = this.template_fields.workers.findIndex(item => item.id == line_id);

        this.template_fields.workers.splice(index, 1)

        this.loading = false;
        return
      }
      this.loading = true
      Templates.removeMaoObra(line_id, this.fields.template.id).then(()  =>  {
        
        let index = this.template_fields.workers.findIndex(item => item.id == line_id);

        this.template_fields.workers.splice(index, 1)

        this.loading = false;
      })
    },
    removeMaquina(line_id){
      if(this.fields.template == null){
        let index = this.template_fields.machines.findIndex(item => item.id == line_id);

        this.template_fields.machines.splice(index, 1)

        this.loading = false;
        return
      }
      this.loading = true
      Templates.removeMaquina(line_id, this.fields.template.id).then(()  =>  {
        
        let index = this.template_fields.machines.findIndex(item => item.id == line_id);

        this.template_fields.machines.splice(index, 1)

        this.loading = false;
      })
    },
    removeUnidade(line_id){
      if(this.fields.template == null){
        let index = this.template_fields.units.findIndex(item => item.id == line_id);

        this.template_fields.units.splice(index, 1)

        this.loading = false;
        return
      }
      this.loading = true
      Templates.removeUnidade(line_id, this.fields.id).then(()  =>  {
        
        let index = this.template_fields.units.findIndex(item => item.id == line_id);

        this.template_fields.units.splice(index, 1)

        this.loading = false;
      })
    },
    async saveTemplate(){
      await Templates[this.methodTemplate](this.template_fields, this.fields.id).then(() => {

        this.success = true;

        this.reloadPage++

        this.loading = false;

        }).catch(err => {
        this.loading = false;

          if(err.response.status == 422) {

            this.$refs.form.setErrors(err.response.data.errors);
            
            return;
          }

          this.error.title = "Erro " + err.response.status;

          this.error.message = err.response.data.message;

        });
    },
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }


      if(this.response.id != null){
        this.$router.push('/admin/works-settings/works/' + this.response.id);
      }else
        this.$router.push('/admin/works-settings/works');
    },
    async fillBaseData(){
      Work.listStatus().then(({data}) => {
        this.status = Object.keys(data).map(item => {

          return {
            id: item,
            name: data[item],
          }

        });
      });

      await Unit.list().then(({data}) => {
          this.units = data;
      });


      User.list().then(({data}) => {
        this.commissioners = data.filter(item => item.roles.some(role => role.name == "encarregado"));

        this.overseers = data.filter(item => item.roles.some(role => role.name == "maquinista"));

        this.engineers = data.filter(item => item.roles.some(role => role.name == "dto"));
      });

      Diary.listStatus().then(({data}) => {
        this.diariesStatus = Object.keys(data).map(item => {return {id: item, name: data[item]}});
      });

      Work.list().then(({data}) => {
        this.diariesWorks = data;
      });

      Company.list().then(({data})  =>  {
        this.subcontractors = data
      });

      this.filter.work_id = this.fields.id
    },
    submit() {
      let problem = false
      this.fields.work_unit_list.forEach(element => {
        if(element.qty_budgeted == null)
          problem = true
        if(element.budgeted_income == null)
          problem = true
      });

      if(problem == true){
        this.error.message = "Campos na unidade de obra em falta"
        this.error.show = true
        return
      }

      if(this.method == 'create'){
        this.fields.workUnitList = this.workUnitList
      }

      // Verificar se existe pelo menos um encarregado e um engenheiro
      if(this.fields.engineers.length == 0 || this.fields.commissioners.length == 0){
        this.error.message = "A obra deve ter pelo menos 1 Engenheiro e 1 Encarregado"
        this.error.show = true
        return
      }

      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

       /* if(this.fields.client != null)
          this.fields.id_client = this.fields.client.id*/

        Work[this.method](this.fields, this.fields.id).then((resp) => {

          this.success = true;

          this.response.id = resp.data.id

          this.$refs.ref_fotos.submit()

          this.$emit('update-object')

          this.loading = false;
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
      })
    },
    getTableDate(date) {
      return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
    },
    viewDiary(item){
      //this.$router.push('/admin/diaries/' + item.id);

      window.open('/admin/diaries/' + item.id, '_blank')
    },
    searchDiaries(){


      this.diariesLoading = true;

      let filter = {...this.filter};


      let request = {
        sortBy: this.diariesOptions.sortBy,
        sortDesc: this.diariesOptions.sortDesc,
        page: this.diariesOptions.page,
        limit: this.diariesOptions.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["diaries-work-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.diariesOptions,
      });

      Diary.search(request).then(response => {

        let data = response.data;

        this.diariesTotal = data.total;

        this.diariesItems = data.data.map((item) => {

          return item;
        });

        this.diariesLoading = false;

      });
    },
  },
  computed: {
    isCopyWorkNull(){
      return this.copyWork == null
    },
    computedChoosingWorkUnit(){
      let returner = []


      this.fields.work_unit_list.forEach(element => {
        if(element.id_work_unit != this.newWorkUnit.id_work_unit)
          returner.push(element)
      });
      return returner
    },
  }
};
</script>
