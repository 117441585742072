var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","min-width":"auto","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data inicial","prepend-inner-icon":"mdi-calendar","readonly":""},on:{"click:clear":function($event){_vm.fields.date = null}},model:{value:(_vm.fields.start_date),callback:function ($$v) {_vm.$set(_vm.fields, "start_date", $$v)},expression:"fields.start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.initialDate),callback:function ($$v) {_vm.initialDate=$$v},expression:"initialDate"}},[_c('v-date-picker',{attrs:{"allowed-dates":function (date) { return date <= new Date(new Date()+1).toISOString().substr(0, 10); },"locale":"pt-pt"},on:{"input":function($event){_vm.initialDate = false;}},model:{value:(_vm.fields.start_date),callback:function ($$v) {_vm.$set(_vm.fields, "start_date", $$v)},expression:"fields.start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","min-width":"auto","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data final","prepend-inner-icon":"mdi-calendar","readonly":""},on:{"click:clear":function($event){_vm.fields.date = null}},model:{value:(_vm.fields.end_date),callback:function ($$v) {_vm.$set(_vm.fields, "end_date", $$v)},expression:"fields.end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.finalDate),callback:function ($$v) {_vm.finalDate=$$v},expression:"finalDate"}},[_c('v-date-picker',{attrs:{"allowed-dates":function (date) { return date <= new Date(new Date()+1).toISOString().substr(0, 10); },"locale":"pt-pt"},on:{"input":function($event){_vm.finalDate = false;}},model:{value:(_vm.fields.end_date),callback:function ($$v) {_vm.$set(_vm.fields, "end_date", $$v)},expression:"fields.end_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.searchWorkerWork}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.worker_work,"server-items-length":_vm.worker_work.length,"loading":_vm.loading,"footer-props":_vm.footer,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.measures",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.measures[0].horas)+"h ")])]}},{key:"item.performance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.measures[0].rendimento_uo)+"% ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }