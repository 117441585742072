var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-16"},[_c('v-breadcrumbs',{staticClass:"pl-0",attrs:{"large":"","items":_vm.crumbs,"divider":"/"}}),_c('h1',{staticClass:"primary--text"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-hard-hat")]),_vm._v(" "),_c('span',[_vm._v("Gestão de Clientes")])],1),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-forward")]),_vm._v(" Visualizar "),_c('v-icon',{staticClass:"ml-4",attrs:{"color":"red"}},[_vm._v(" mdi-delete ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-forward")]),_vm._v(" Remover "),_c('div',{staticStyle:{"position":"relative"}},[_c('v-switch',{staticStyle:{"position":"absolute"},attrs:{"color":"success","label":"Mostrar eliminados"},on:{"change":_vm.searchClients},model:{value:(_vm.filter.deleted_client),callback:function ($$v) {_vm.$set(_vm.filter, "deleted_client", $$v)},expression:"filter.deleted_client"}}),_c('v-container',[_c('v-speed-dial',{attrs:{"absolute":"","top":"","right":"","direction":"bottom","open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","x-large":"","fab":""},model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},'v-btn',attrs,false),on),[(_vm.speedDial)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('span',[_vm._v("Opções")])])]},proxy:true}]),model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},[_c('v-tooltip',{attrs:{"right":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"info","large":""},on:{"click":function($event){_vm.searching = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}])},[_c('span',[_vm._v("Pesquisar")])]),_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$root.session.hasPermission(['super', 'clients.create']))?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"secondary","large":""},on:{"click":function($event){return _vm.openModalNewClient()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Registar")])])],1)],1),_c('v-data-table',{staticClass:"mt-16",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTableDate(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTableDate(item.updated_at))+" ")]}},{key:"item.deleted_client",fn:function(ref){
var item = ref.item;
return [_c('IconView',{staticClass:"mr-2",on:{"click":function($event){return _vm.viewClient(item)}}}),(_vm.$root.session.hasPermission(['super', 'clients.delete']))?_c('IconRemove',{staticClass:"mr-2",attrs:{"reverse":_vm.filter.deleted_client},on:{"on-submit":function($event){return _vm.deleteClient(item)}}}):_vm._e()]}}])})],1),_c('v-navigation-drawer',{staticClass:"pt-6",attrs:{"fixed":"","right":"","hide-overlay":"","width":"520"},model:{value:(_vm.searching),callback:function ($$v) {_vm.searching=$$v},expression:"searching"}},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"fab":"","small":"","color":"secondary"},on:{"click":function($event){_vm.searching = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"absolute":"","right":"","small":"","color":"error"},on:{"click":_vm.resetClients}},[_vm._v(" Limpar Campos ")])],1)],1),_c('v-list',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchClients.apply(null, arguments)}}},[_c('v-list-item',[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-barcode","label":"Nome","clearable":""},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1),_c('v-row',{staticClass:"mt-10",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v(" Pesquisar ")])],1)],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.approved},on:{"on-ok":function($event){_vm.approved = false}}},[_vm._v(" Empresa atualizada com sucesso ")]),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.addClient),callback:function ($$v) {_vm.addClient=$$v},expression:"addClient"}},[(_vm.addClient)?_c('AddClient',{attrs:{"editClient":_vm.client_selected},on:{"close":function($event){_vm.addClient = false},"close-update":function($event){return _vm.fillBaseData()}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }