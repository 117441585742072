<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-account-hard-hat</v-icon> <span>Gestão de Colaboradores</span>
      </h1>
      <v-icon color="primary">
        mdi-eye
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Visualizar

      <v-icon color="red" class="ml-4">
        mdi-delete
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Remover

      <div style="position:relative">
        <v-switch
          style="position:absolute"
          v-model="filter.deleted_worker"
          color="success"
          @change="searchWorkers"
          label="Mostrar eliminados"
        ></v-switch>
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Pesquisar</span>
            </v-tooltip>
            <v-tooltip right color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="success"
                  large
                  @click="exporting = true;"
                  v-if="$root.session.hasPermission(['super', 'export.workers'])"
                >
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Exportar</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  to="/admin/workers-settings/workers/create"
                  v-if="$root.session.hasPermission(['super', 'workers.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Registar</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16"
          locale="pt-pt"
        >
          <template v-slot:item.value="{ item }">
            {{item.value.toFixed(2)}}€
          </template>

          <template v-slot:item.category="{ item }">
            {{item.category.name}}
          </template>

          <template v-slot:item.company="{ item }">
            {{item.company == null ? 'Interno' : item.company.name}}
          </template>

          <template v-slot:item.actions="{item}">
            <IconView
              class="mr-2"
              @click="viewWorker(item)"
            />
            <IconRemove
              v-if="$root.session.hasPermission(['super', 'workers.delete'])"
              class="mr-2"
                :reverse="filter.deleted_worker"
              @on-submit="deleteWorker(item)"
            />
          </template>
        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetWorkers">
              Limpar Campos
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchWorkers">

            <v-list-item>
              <v-text-field
                v-model="filter.code"
                prepend-inner-icon="mdi-barcode"
                label="Código"
                clearable
              ></v-text-field>
            </v-list-item>
            
            <v-list-item>
              <v-text-field
                v-model="filter.name"
                prepend-inner-icon="mdi-form-textbox"
                label="Nome"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
               <v-select
                  v-model="filter.category_id"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  menu-props="auto"
                  label="Categoria"
                  prepend-inner-icon="mdi-shape"
                  clearable
              ></v-select>
            </v-list-item>

            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              Pesquisar
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
      <DialogSuccess :opened="started" @on-ok="started = false" >
        Exportação Iniciada, verifique as notificações para extrair o documento!
      </DialogSuccess>
      <Dialog 
        :opened="exporting">
        <template #content>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-select
                    v-model="worker.export.report"
                    :items="reports"
                    item-text="name"
                    item-value="id"
                    menu-props="auto"
                    label="Relatório"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-menu
                  v-model="export_started_at"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        label="Data Inicio"
                        v-model="worker.export.started_at"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="worker.export.started_at"
                    locale="pt-pt"
                    @input="export_started_at = false;"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                  v-model="export_finished_at"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        label="Data Fim"
                        v-model="worker.export.finished_at"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="worker.export.finished_at"
                    locale="pt-pt"
                    @input="export_finished_at = false;"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template #footer>
            <v-btn
              color="error"
              depressed
              @click="exporting = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              depressed
              @click="exportReport"
            >
              Exportar
            </v-btn>
        </template>
      </Dialog>
  </section>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import Category from "@/api/Category.js";
import Worker from "@/api/Worker.js";
import dayjs from 'dayjs';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Dialog from '@/components/ui/Dialog.vue';

export default {
  components: {
    IconRemove,
    IconView,
    DialogSuccess,
    Dialog
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchWorkers()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "GPD - Gestão de Colaboradores";

    Category.list().then(({data}) => {
      this.categories = data;
    });
  },
  data: () => ({
    started: false,
    exporting: false,
    export_finished_at: false,
    export_started_at: false,
    worker: {
      export: {
        started_at: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        finished_at: dayjs().format("YYYY-MM-DD"),
        report: "extra_hours",
      }
    },
    reports: [
      {
        id: "extra_hours",
        name: "Horas Extra"
      },
      {
        id: "work_entries",
        name: "Ponto de Mão-de-obra"
      },
      {
        id: "meals",
        name: "Refeições"
      },
      {
        id: "night_work",
        name: "Trabalho Noturno"
      },
      {
        id: "performance",
        name: "Performance"
      },
    ],
    headers: [
      {
        text: 'Nome',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Custo',
        sortable: true,
        value: 'value',
      },
      {
        text: 'Telefone',
        sortable: true,
        value: 'phone_number',
      },
      {
        text: 'Categoria',
        sortable: true,
        value: 'category',
      },
      {
        text: 'Empresa',
        sortable: true,
        value: 'company',
      },
      { 
        text: 'Opções', 
        value: 'actions', 
        sortable: false 
      },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      code:null,
      name: null,
      category_id: null,
      deleted_worker: false
    },
    categories: [],
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "workers.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();
  },
  methods:{
    getTableDate(date) {
      return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
    },
    fillBaseData() {

      if(localStorage["workers-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["workers-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchWorkers(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        deleted_worker: this.filter.deleted_worker
      };
    
      Object.assign(request, filter);

      localStorage["workers-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Worker.search(request).then(response => {

        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetWorkers()
    {
      this.filter = {
      };

      this.searchWorkers();
    },
    viewWorker(item){
      this.$router.push('/admin/workers-settings/workers/' + item.id);
    },
    deleteWorker(item){
      if(this.filter.deleted_worker)
        item.deleted_worker = false;
      else
        item.deleted_worker = true
      
        item.category_id = item.category.id

        console.log("AQI")
        console.log(item)
      Worker.update(item, item.id)
        .then(() => {
          this.searchWorkers();
        });
    },
    async exportReport(){ 
      


      await Worker.exportReport(this.worker.export)
                    .then((resp) => {

                        if(resp.data.success){
                            if(resp.data.object == null){
                                this.error.message = "Não há dados para mostrar"
                                this.error.show = true
                                this.exportingDocument = false
                                return  
                            }else{
                                window.open(resp.data.object)
                            }
                        }

                });
    }
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão Colaboradores',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
