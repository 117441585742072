<template>
    <section class="mt-16">
        <v-progress-linear v-if="loading"
        indeterminate
        class="global-loader"
        ></v-progress-linear>

        <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

        <h1 class="primary--text">
            <v-icon color="primary">mdi-calendar</v-icon> <span>Calendário</span>
        </h1>
        <div style="position:relative">
            <validation-observer ref="form" v-slot="{ invalid }">
                <v-form @submit.prevent="submit">
                    <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-date-picker
                                        full-width
                                        v-model="selectedDay"
                                        event-color="green lighten-1"
                                        :events="getEvents"
                                        @click:date="contextMenu"
                                    ></v-date-picker>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-card elevation="0">
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                    <validation-provider v-slot="{ errors }" vid="selectedDay" name="Dia selecionado" rules="required">
                                                        <v-text-field 
                                                            :disabled="true"
                                                            v-model="selectedDay"
                                                            label="Dia selecionado"
                                                            clearable
                                                            :error-messages="errors"
                                                        />
                                                    </validation-provider>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <validation-provider v-slot="{ errors }" vid="type" name="Tipo" rules="required">
                                                        <v-autocomplete                                        
                                                            v-model="registerType"
                                                            :items="tiposRegisto"
                                                            menu-props="auto"
                                                            label="Tipo de registo"
                                                            prepend-icon="mdi-hard-hat"
                                                            single-line
                                                            :error-messages="errors"
                                                        ></v-autocomplete>
                                                    </validation-provider>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <validation-provider v-slot="{ errors }" vid="description" name="Descrição" rules="required">
                                                        <v-text-field
                                                            v-model="eventDescription"
                                                            label="Descrição do evento"
                                                            :error-messages="errors"
                                                            clearable
                                                        />
                                                    </validation-provider>
                                                </v-col>
                                            </v-row>
                                            
                                        </v-card-text>
                                        <v-card-text v-if="$root.session.hasPermission(['super', 'calendar.create', 'calendar.update'])">
                                            <v-btn :disabled="invalid" depressed color="primary" type="submit">
                                            Registar
                                            </v-btn>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-title>
                            Feriados registados
                        </v-card-title>
                        <v-card-text>
                            <v-simple-table v-if="events.length > 0">
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left">
                                        Dia
                                    </th>
                                    <th class="text-left">
                                        Tipo
                                    </th>
                                    <th class="text-left">
                                        Evento
                                    </th>
                                    <th class="text-left">
                                        Opções
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="item in events"
                                    :key="item.name"
                                    >
                                    <td>{{ item.day_event }}</td>
                                    <td>{{ item.type }}</td>
                                    <td>{{ item.event }}</td>
                                    <td>
                                        <IconRemove
                                            class="mr-2"
                                            v-if="$root.session.hasPermission(['super', 'calendar.delete'])"
                                            @on-submit="deleteEvent(item)"
                                        />
                                    </td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                            <span v-else>Sem eventos registados</span>
                        </v-card-text>
                    </v-card>
                </v-form>
            </validation-observer>
        </div>
        <DialogSuccess :opened="success" @on-ok="success = false" >
            {{ msgSuccess }}
        </DialogSuccess>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
    </section>
</template>
<script>
import APICalendar from '@/api/CalendarEvents.js'
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import IconRemove from '@/components/ui/IconRemove.vue';
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';

export default{
    components:{
        DialogSuccess,
        IconRemove,
        ErrorSnackbar
    },
    data(){
        return{
            error:{
                message: '',
                show: ''
            },
            msgSuccess: '',
            success: false,
            tiposRegisto: ['Feriado Empresa'],
            registerType: 'Feriado Empresa',
            eventDescription: '',
            events: [],
            loading: true,
            selectedDay: null
        }
    },
    mounted(){
        this.fillBaseData()
        this.loading = false
    },
    methods:{
        async deleteEvent(item){
            this.loading = true
            await APICalendar.delete(item.id).then(() => {
                this.msgSuccess = 'Dia removido com sucesso'
                this.success = true
                this.loading = false
                this.eventDescription = null;
                this.selectedDay = null
                this.fillBaseData()
            });
        },
        contextMenu(year){
            this.events.forEach(element => {
                if(element.day_event == year){
                    this.registerType = element.type
                    this.eventDescription = element.event
                }
            });
        },
        async fillBaseData(){
            await APICalendar.list().then(({data}) => {
                this.events = data
                console.log(this.events)
            });
        },
        async submit(){
            let data = {
                day_event: this.selectedDay,
                event: this.eventDescription,
                type: this.registerType
            }
            this.loading = true

            await APICalendar.create(data).then(() => {
                this.msgSuccess = 'Dia adicionado com sucesso'
                this.success = true
                this.loading = false
                this.eventDescription = null;
                this.selectedDay = null
                this.fillBaseData()
            }).catch(() => {

                this.error.message = "Dados inválidos";
                this.loading = false

                this.error.show = true

            });

            
        }
    },
    computed:{
        crumbs: function() {
            return [
            {
                text: 'Dashboard',
                disabled: false,
                to: '/',
                exact: true,
                },
                {
                text: 'Calendário',
                disabled: true,
                to: window.location.href,
                exact: true,
                },
            ];
        },
        getEvents(){
            let eventsArray = []
            this.events.forEach(element => {
                eventsArray.push(element.day_event)
            });
            return eventsArray
        }
    }
}
</script>