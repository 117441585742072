<template>
    <v-container fluid>
       <v-progress-linear v-if="loading"
          indeterminate          
        ></v-progress-linear>
        <v-row v-if="!loading">
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            v-model="worker_model"
                            :items="getWorkers"
                            item-value="id"
                            :item-text="item => item.code + ' - ' + item.name"
                            dense outlined clearable
                            label="Colaboradores"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn :disabled="worker_model == null" @click="insertWorker" class="success" rounded>Inserir</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left">
                                Código
                            </th>
                            <th class="text-left">
                                Nome
                            </th>
                            <th class="text-left">
                                Tipo
                            </th>
                            <th class="text-left">
                                Opções
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in computeFieldsWorkers"
                                :key="item.code"
                            >
                                <td>{{ item.code }}</td>
                                <td>{{ item.name }}</td>
                                <td>
                                    {{item.company == null ? 'Interno' : 'Externo'}}
                                </td>
                                <td>
                                    <IconRemove
                                        class="mr-2"
                                        @on-submit="deleteWorker(item)"
                                      />
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
      <SuccessSnackBar v-model="successSnack.show" :message="successSnack.message"/>
    </v-container>
</template>
<script>
//import Work from '@/api/Work.js'
import Worker from '@/api/Worker.js'
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';
import IconRemove from '@/components/ui/IconRemove.vue';

export default{
    name:"WorkWorkers",
    components:{
        SuccessSnackBar,
        IconRemove
    },
    props: {
        fields: Object,
        method: String
    },
    data(){
        return{
            loading: true,
            successSnack:{
                show: false,
                message: ''
            },
            worker_model: null,
            workers: []
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        deleteWorker(item){
            let i = 0; let pos = -1;
            this.fields.workers.forEach(element => {
                if(element.id == item.id)
                    pos = i 
                
                i++
            });

            if(pos != -1){
                this.fields.workers.splice(pos, 1)
            }

            /*this.fields.workers.forEach(element => {
                if(element.id != item.id)
                    array_auxiliar.push(element.id)
            });*/
            
            /*let data = {
                workers: array_auxiliar
            }*/

            /*
            Work.setWorkers(data, this.$route.params.id).then(()   =>  {
                this.worker_model = null
                this.successSnack.message = "Colaborador removido com sucesso"
                this.successSnack.show=true
                this.$emit('update')
            });*/
        },
        insertWorker(){
            let ob = null
            
            this.getWorkers.forEach(element => {
                if(element.id == this.worker_model)
                    ob = element
            });

            if(ob != null)
                this.fields.workers.push(ob)

            /*let array_auxiliar = []
            this.fields.workers.forEach(element => {
                array_auxiliar.push(element.id)
            });

            array_auxiliar.push(this.worker_model)

            let data = {
                workers: array_auxiliar
            }*/

            
            /*Work.setWorkers(data, this.$route.params.id).then(()   =>  {
                this.worker_model = null
                this.successSnack.message = "Colaborador adicionado com sucesso"
                this.successSnack.show=true
                this.$emit('update')
            });*/
        },
        fillBaseData(){
            Worker.list().then((resp)   =>  {
               this.workers = resp.data 
               this.loading= false
            });
        }
    },
    computed:{
        computeFieldsWorkers(){
            return this.fields.workers
        },
        getWorkers(){
            let auxiliar = []

            
            this.workers.forEach(w => {
                let exists = false
                this.fields.workers.forEach(wa => {
                    if(wa.id == w.id)
                        exists = true
                });

                if(exists == false)
                    auxiliar.push(w)
            });

            return auxiliar
        }
    }
}
</script>