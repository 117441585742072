<template style="background-color:white;">
    <v-card>
     <v-card-title>
       <span class="text-h5">{{title}}</span>
     </v-card-title>
       <v-card-text>
           <v-container class="mt-4">
               <v-row>
                   <v-col>
                       <ValidationProvider :vid="vid" :name="$attrs.label"  v-slot="{errors}">
                           <v-textarea
                               outlined
                               :label="label"
                               v-bind="$attrs"
                               v-on="$listeners"
                               name="input-7-4"
                               :error-messages="errors"
                               @change="changeValue"
                               :readonly="locked"
                           ></v-textarea>
                       </ValidationProvider>
                   </v-col>
               </v-row>
           </v-container>
       </v-card-text>
   </v-card>
</template>
<script>
export default {
   name: 'ModalTextArea',
   props:{
       vid: String,
       model:String,
       title:String,
       label:String,
       locked:{
        type: Boolean,
        default:false
       }
   },
   methods:{
       changeValue(e){
           this.$emit('change-value', e)
       }
   }
}
</script>