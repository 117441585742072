<template>
    <v-container fluid>
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="itemsTotal"
            :loading="loading"
            :footer-props="footer"
            :options.sync="options"
            locale="pt-pt"
        >

        <template v-slot:item.document="{item}">
            <IconExport
              class="mr-2"
              text="Download"
              v-if="item.file_path != null"
              @click="downloadFile(item.file_path)"
            />
        </template>

        <template v-slot:item.code="{item}">
            <v-row>
                <v-col cols="12">
                    <v-btn x-small text @click="$emit('view', item)" v-if="$root.session.hasPermission(['super', 'breakdown.update'])">
                        <v-icon class="mr-2">mdi-eye</v-icon>
                        {{item.code}}
                    </v-btn>
                </v-col>
            </v-row>
        </template>

        <template v-slot:item.corrective="{item}">
            <v-row v-if="item.corrective != null">
                <v-col cols="12">                    
                    <v-btn x-small text @click="$emit('view', item.corrective)">
                        <v-icon class="mr-2">mdi-eye</v-icon>
                        {{item.corrective.breakdown_date}}
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12"></v-col>
            </v-row>
        </template>

        <template v-slot:item.actions="{item}">
            
            <IconRemove
              class="mr-2"
              v-if="$root.session.hasPermission(['super', 'breakdown.delete'])"
              @on-submit="deleteIntervention(item)"
            />
        </template>

        </v-data-table>

        <DialogSuccess :opened="success" @on-ok="success=false;">
          Intervenção removida com sucesso!
        </DialogSuccess>

    </v-container>
</template>
<script>
import Machine from '@/api/Machine.js'
import Files from '@/api/Files.js'
import IconExport from '@/components/ui/IconExport.vue';
import IconRemove from '@/components/ui/IconRemove.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default{
    name: 'List',
    components:{
        DialogSuccess,
        IconRemove,
        IconExport
    },
    props:{
        selectedDate: String
    },
    watch: { 
        'options': {
            immediate: false,
            handler () {
                setTimeout(() => {this.searchData()}, 10);
            },
            deep: true,
        },
        selectedDate:{
            immediate: true,
            handler(){
                setTimeout(() => {this.searchData()}, 10);
            }
        }
    },
    data(){
        return{            
            success: false,
            headers:[
                {
                    text: 'Data',
                    sortable: true,
                    value: 'breakdown_date',
                    align: 'center'
                },
                {
                    text: 'Código',
                    sortable: true,
                    value: 'code',
                    align: 'center'
                },
                {
                    text: 'Estado',
                    sortable: true,
                    value: 'status',
                    align: 'center'
                },
                {
                    text: 'Descrição',
                    sortable: true,
                    value: 'breakdown',
                    align: 'center'
                },
                {
                    text: 'Corrigida a',
                    sortable: true,
                    value: 'corrective',
                    align: 'center'
                },
                {
                    text: 'Opções',
                    sortable: true,
                    value: 'actions',
                    width: '5%',
                    align: 'center'
                }
            ],
            items:[],
            itemsTotal: 0,
            loading: true,
            footer: {
                'items-per-page-options': [5, 10, 15, 20],
                'items-per-page-text': "Linhas por página"
            },
            options: {}
        }
    },
    methods:{
        getFilename(file){
            if(file == null)
                return ''
            return file.substring(file.lastIndexOf('/')+1)
        },
        downloadFile(path){
            let data = {
                path: path
            }
            Files.get(data)
            .then((response) => {
                console.log(response)
                let documentName =  this.getFilename(path)

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        deleteIntervention(item){
            Machine.deleteBreakdown(item.id)
            .then(() => {
                this.success = true
                this.$emit('reload')
            });
        },
        async searchData(){
            this.loading = true
            let filter = {...this.filter};
            

            let request = {
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                page: this.options.page,
                limit: this.options.itemsPerPage,
                machine_id: this.$route.params.id,
                breakdown_date: this.selectedDate
            };

    
            Object.assign(request, filter);

            localStorage["breakdowns-machine-" + this.$root.session.id] = JSON.stringify({
                filter: this.filter,
                options: this.options,
            });

            await Machine.searchBreakdowns(request).then(response => {

                this.items = response.data.data
                this.itemsTotal = response.data.total
                
                
                this.loading = false;

            });
        },    
    }
}
</script>