<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-notebook</v-icon> <span>Edição de Diário</span>
      </h1>

      <div class="mt-16">
        <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>

        <template v-if="loading">
          <v-row>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-skeleton-loader
                type="button"
              ></v-skeleton-loader>
          </v-row>
        </template>
        <diary v-else method="update" :diary="diary" @reloadAll="reloadAll" @reload="reload" :key="reloadAllComponent"></diary>
      </div>
  </section>
</template>
<script>
import Diary from '@/components/diaries/Diary'
import DiaryApi from "@/api/Diary.js";
import { getDiariesOffline } from '../../idb'

export default {
  components: {
    Diary
  },
  beforeCreate(){
    document.title = "GPD - Edição de Diário";
  },
  data: () => ({
      diary: {},
      loading:true,
      reloadAllComponent: 0
  }),
  async mounted(){

    


    if(!this.$root.session.hasPermission(["super", "diaries.update", 'diaries.read'])) {
      this.$router.push('/admin');
    }

    if(this.$route.name == "Offline Diary"){
      let diaries = await getDiariesOffline();


      diaries.forEach(element => {
        if(element.id == this.$route.params.id)
          this.diary = element
      });
      this.loading = false
      return;
    }

    DiaryApi.find(this.$route.params.id).then(({data}) => { this.diary = data; this.loading = false;});
  },
  methods:{
    reload(fields){
      DiaryApi.find(this.$route.params.id).then(({data}) => { 
        this.diary.machine_work_units = data.machine_work_units
        this.diary.worker_work_units = data.worker_work_units
        //this.diary.work_units = data.work_units

        fields.machine_work_units = data.machine_work_units
        fields.worker_work_units = data.worker_work_units
        //fields.work_units = data.work_units
        
        this.loading = false;
      });
    },
    reloadAll(){
      location.reload()
    },
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão de Partes Diárias',
          disabled: false,
          to: '/admin/diaries',
          exact: true,
        },
        {
          text: 'Edição de Diário',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
