<template>
  <v-app>
    <v-main>
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col cols="12">

            <div class="d-flex justify-center pb-8">
              <v-img
              lazy-src="@/assets/gpd_logo.png"
              src="@/assets/gpd_logo.png"
              max-width="250"
              ></v-img>
            </div>

            <h1 class="h1 pb-8 text-center">Verificação Dupla Autenticação</h1>

            <validation-observer
              ref="login"
              v-slot="{ invalid }"
            >
              <v-form @submit.prevent="submit">
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                    <validation-provider name="Código Dupla Autenticação" vid="code" rules="required" v-slot="{ errors }">
                      <v-text-field v-model="code" label="Código Dupla Autenticação" prepend-inner-icon="mdi-numeric" :error-messages="errors"></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                    <v-btn
                      depressed
                      color="primary"
                      class="mr-4"
                      type="submit"
                      :disabled="invalid"
                      large
                      block
                    >
                      Validar Código
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </validation-observer>
             <div class="d-flex justify-center mb-5 mt-5">
              <v-img
              lazy-src="@/assets/geonorte.png"
              src="@/assets/geonorte.png"
              max-width="150"
              ></v-img>
            </div>
            <div class="d-flex justify-center">
              <span class="body-2 mr-3">Powered By </span>
              <v-img
              lazy-src="@/assets/logo_dream.png"
              src="@/assets/logo_dream.png"
              max-width="80"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import User from "@/api/User.js"

export default {
  components: {
  },
  data: () => ({
    code: "",
  }),
  beforeCreate(){
    document.title = "GPD - Login 2FA";

    if(localStorage['auth'] == 1){
      this.$router.push('/admin');
    }
  },
  methods:{
    submit () {
        this.$refs.login.validate().then((valid) => {
          if (!valid) {
            return;
          }

          User.login2Fa(this.code)
            .then(() => {

                localStorage['auth'] = 1;

                this.$router.push('/admin');

                
            }).catch(err => {

              if(err.response.status == 422) {

                this.$refs.login.setErrors(err.response.data.errors);
                
                return;
              }

              this.$router.push('/');

          });
        });
      },
  }
};
</script>
