import BaseApi from "./BaseApi";

export default class WorkUnitList extends BaseApi{
    construct() {}
 
    static async create(params) {
        return await WorkUnitList.getAxiosInstance().post(WorkUnitList.getApiUrl(),params);
    }

    static async delete(id) {
        return await WorkUnitList.getAxiosInstance().delete(WorkUnitList.getApiUrl() + id);
    }

    static async update(params, id) {
        return await WorkUnitList.getAxiosInstance().put(WorkUnitList.getApiUrl() + id,params);
    }

    static async get(params) {
        let url = WorkUnitList.getApiUrl() + params;

        return WorkUnitList.getAxiosInstance().get(url);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/work-unit-list/";
    }

}