<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="auto">
                <svg height="25" width="25">
                    <circle cx="25" cy="25" r="40" stroke="black" stroke-width="3" fill="orange" />
                </svg>
            </v-col>
            <v-col cols="12" md="auto">
                A Aguardar
            </v-col>
            <v-col cols="12" md="auto">
                <svg height="25" width="25">
                    <circle cx="25" cy="25" r="40" stroke="black" stroke-width="3" fill="green" />
                </svg>
            </v-col>
            <v-col cols="12" md="auto">
                Iniciadas
            </v-col>
            <v-col cols="12" md="auto">
                <svg height="25" width="25">
                    <circle cx="25" cy="25" r="40" stroke="black" stroke-width="3" fill="red" />
                </svg>
            </v-col>
            <v-col cols="12" md="auto">
                Terminadas
            </v-col>
        </v-row>
        <GmapMap
          v-if="!loading"
          class="mt-4 elevation-10"
          ref="gmap"
          :center="center"
          :options="getOptions"
          :zoom="zoom"
          map-type-id="roadmap"
          style="width: 100%; height: 800px"
        >

        <GmapMarker
            v-for="m in startedMarkers"
            :key="m.id"
            :icon="getIcon(m.status)"
            :clickable="true"
            :position="getPosition(m.lat, m.lng)"
            @click="openMark(m)"
          />

          <GmapMarker
            v-for="m in waitingMarkers"
            :key="m.id"
            :icon="getIcon(m.status)"
            :clickable="true"
            :position="getPosition(m.lat, m.lng)"
            @click="openMark(m)"
          />

          <GmapMarker
            v-for="m in finishedMarks"
            :key="m.id"
            :icon="getIcon(m.status)"
            :clickable="true"
            :position="getPosition(m.lat, m.lng)"
            @click="openMark(m)"
          />
          
            <gmap-info-window
                :options="{
                maxWidth: 400,
                pixelOffset: { width: 0, height: -35 }
                }"
                :position="infoWindowOpen.position"
                :opened="infoWindowOpen.show"
                >
                <v-container fluid style="min-width: 220px;">
                    <v-row justify="center" v-if="infoWindowOpen.status != 'FINISHED'">
                        <v-col cols="12" md="auto">
                            <v-btn fab small :disabled="infoWindowOpen.day == 0" @click="removeDay">
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>                            
                        </v-col>
                        <v-col cols="12" md="auto" class="font-weight-bold mt-2">
                            {{getDay()}}
                        </v-col>
                        <v-col cols="12" md="auto">
                            <v-btn fab small :disabled="infoWindowOpen.day == 2" @click="addDay">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>                            
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="my-0 py-1" cols="12" :md="showItem(item) ? 6 : 12" v-for="(item, index) in infoWindowOpen.metheo" :key="item">
                            <div v-if="infoWindowOpen.metheo && infoWindowOpen.status != 'FINISHED'">
                                <span v-if="showItem(item)" :class="index % 2 != 0 ? 'font-weight-bold' : ''">{{item}}</span>
                                <div v-else>
                                    <v-img style="margin: auto; display: block;" width="50" :src="item" />
                                    <v-divider class="mt-2 mb-2"></v-divider>
                                </div>
                            </div>
                            <div v-else>
                                <span v-if="showItem(item)" :class="index % 2 === 0 ? 'font-weight-bold' : ''">{{item}}</span>
                            </div>
                        </v-col>
                    </v-row>                    
                    <v-row align="center">                                        
                        <v-col cols="12" align="center">
                            <v-btn rounded class="primary" small @click="openLocation('/admin/works-settings/works/' + infoWindowOpen.object.work_id)">Abrir</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </gmap-info-window>
        
        </GmapMap>
    </v-container>
</template>
<script>
export default{
    name:"MapMarkers",
    props:{
        markers: Array,
        filter: Object
    },
    data(){
        return{
            infoWindowOpen: {
                show: false,
                day: 0,
                msg: '',
                object: null,
                link: null,
                metheo: null,
                status: null,
                position: {
                    lat: null,
                    lng: null
                }
            },
            loading: true,
            zoom: 7,
            center:{
                /*lat: 41.25080510000001,
                lng: -8.493347300000002*/
                lat: 40.39111111,
                lng:  -6.91333333
            },
        }
    },
    mounted(){
        this.loading = false
    },
    methods:{
        removeDay(){
            this.infoWindowOpen.day--
            this.openMark(this.infoWindowOpen.object)
        },
        addDay(){
            this.infoWindowOpen.day++
            this.openMark(this.infoWindowOpen.object)
        },
        getDay(){
            let hoje = new Date()

            if(this.infoWindowOpen.day > 0)
                hoje.setDate(hoje.getDate() + this.infoWindowOpen.day)
            return hoje.getDate()+'/'+(hoje.getMonth()+1)+'/'+hoje.getFullYear(); 
        },
        showItem(item){
            //weatherapi
            item = item +''
            if(item.includes('weatherapi'))
                return false
        
        
            return true
        },
        openLocation(link){
            this.$router.push(link);
        },
        openMark(m){
            this.infoWindowOpen.show = false
            this.infoWindowOpen.object = m
            this.infoWindowOpen.msg = m.name
            this.infoWindowOpen.status = m.status
            this.infoWindowOpen.link = '/admin/works-settings/works/' + m.work_id;

            let today = new Date();
            today.setDate(today.getDate() + this.infoWindowOpen.day);

            let hoje = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate(); 

            this.markers.forEach(element => {
                if(element.work_id == m.work_id){
                    let dia_avaliado = new Date(element.date)
                    let dia = dia_avaliado.getFullYear()+'/'+(dia_avaliado.getMonth()+1)+'/'+dia_avaliado.getDate(); 

                    if(hoje == dia){
                        m.methereology = element.methereology
                        if(typeof m.methereology  === 'object'){
                            m.methereology = JSON.stringify(m.methereology)
                        }
                    }
                }
            });       
            
     

            this.infoWindowOpen.metheo = [];

            
            this.infoWindowOpen.metheo.push('Nome:')
            this.infoWindowOpen.metheo.push(m.name)

            if(m.methereology != null && m.status != 'FINISHED'){         
                    
                m.methereology = JSON.parse(m.methereology)

                
                this.infoWindowOpen.methereology = true
                
                this.infoWindowOpen.metheo.unshift(m.methereology.day.condition.icon)

                this.infoWindowOpen.metheo.push('Max Cº:')
                this.infoWindowOpen.metheo.push(m.methereology.day.maxtemp_c)
                this.infoWindowOpen.metheo.push('Min Cº:')
                this.infoWindowOpen.metheo.push(m.methereology.day.mintemp_c)
                this.infoWindowOpen.metheo.push('Previsão:')
                this.infoWindowOpen.metheo.push(m.methereology.day.condition.text)
                this.infoWindowOpen.metheo.push('Precipitação (mm):')
                this.infoWindowOpen.metheo.push(m.methereology.day.totalprecip_mm)
                this.infoWindowOpen.metheo.push('Vento (kph):')
                this.infoWindowOpen.metheo.push(m.methereology.day.maxwind_kph)    
            }

            this.infoWindowOpen.position = {
                lat: m.lat,
                lng: m.lng
            }
            this.infoWindowOpen.show = true
        },
        getPosition(lat, lng){
            return {
                lat: lat,
                lng: lng
            }
        },
        getIcon(status){
            if(status == 'STARTED'){
                return {
                    path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                    fillColor: "green",
                    fillOpacity: 0.6,
                    strokeWeight: 0,
                    rotation: 0,
                    scale: 1,
                }
            }

            if(status == 'FINISHED'){
                return {
                    path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                    fillColor: "red",
                    fillOpacity: 0.6,
                    strokeWeight: 0,
                    rotation: 0,
                    scale: 1,
                }
            }
            
            return {
                path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                fillColor: "orange",
                fillOpacity: 0.6,
                strokeWeight: 0,
                rotation: 0,
                scale: 1,
            }
        },
    },
    computed:{
        getOptions(){
            let options = {
                clickableIcons: false,
                mapTypeControl: false, 
                streetViewControl: false,
                fullscreenControl: false,
                styles: [
                    {
                    "elementType": "geometry",
                    "stylers": [
                        {
                        "color": "#f5f5f5"
                        }
                    ]
                    },
                    {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                        "visibility": "off"
                        }
                    ]
                    },
                    {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                        "color": "#616161"
                        }
                    ]
                    },
                    {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                        "color": "#f5f5f5"
                        }
                    ]
                    },
                    {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                        "color": "#bdbdbd"
                        }
                    ]
                    },
                    {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                        "color": "#eeeeee"
                        }
                    ]
                    },
                    {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                        "color": "#757575"
                        }
                    ]
                    },
                    {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                        "color": "#e5e5e5"
                        }
                    ]
                    },
                    {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                        "color": "#9e9e9e"
                        }
                    ]
                    },
                    {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                        {
                        "color": "#ffffff"
                        }
                    ]
                    },
                    {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                        "color": "#757575"
                        }
                    ]
                    },
                    {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                        "color": "#dadada"
                        }
                    ]
                    },
                    {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                        "color": "#616161"
                        }
                    ]
                    },
                    {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                        "color": "#9e9e9e"
                        }
                    ]
                    },
                    {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [
                        {
                        "color": "#e5e5e5"
                        }
                    ]
                    },
                    {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [
                        {
                        "color": "#eeeeee"
                        }
                    ]
                    },
                    {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                        "color": "#c9c9c9"
                        }
                    ]
                    },
                    {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                        "color": "#9e9e9e"
                        }
                    ]
                    }
                ]
                }


            return options
        },
        waitingMarkers(){
            if(this.filter != null)
                if(this.filter.status != null){
                    if(this.filter.status != 'WAITING')
                        return [];
                }
            let wm = []

            this.markers.forEach(element => {
                if(element.status == 'WAITING')
                    wm.push(element)
            });

            return wm
        },
        startedMarkers(){
            if(this.filter != null)
                if(this.filter.status != null){
                    if(this.filter.status != 'STARTED')
                        return [];
                }
            let wm = []

            this.markers.forEach(element => {
                if(element.status == 'STARTED')
                    wm.push(element)
            });
            

            return wm
        },
        finishedMarks(){
            if(this.filter != null)
                if(this.filter.status != null){
                    if(this.filter.status != 'FINISHED')
                        return [];
                }
            let wm = []

            this.markers.forEach(element => {
                if(element.status == 'FINISHED')
                    wm.push(element)
            });
            

            return wm
        }
    }
}
</script>