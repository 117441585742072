<template>
    <v-container fluid>
        <v-card class="elevation-0">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3">
                        <v-file-input
                            v-model="fields.logo"
                            :rules="rules"
                            color="primary"
                            dense outlined
                            accept="image/png, image/jpeg, image/bmp"
                            placeholder="Carregar imagem de obra"
                            prepend-icon="mdi-camera"
                            label="Carregar imagem de obra"
                            @change="onFileChange"
                        ></v-file-input>
                    </v-col>
                    <v-col cols="12" md="9">
                        <v-card class="elevation-0">
                            <v-container class="fill-height" fluid>
                                <v-img v-if="img_url" 
                                    style="display: block; margin:auto;"
                                    :src="img_url" 
                                    ref="logoImage"
                                    @load="loadedImage"
                                    contain
                                    :aspect-ratio="16/9"
                                    class="center mt-2"         
                                    max-width="250px"
                                    max-height="250px"/>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-divider></v-divider>
        <SuccessSnackBar v-model="success" :message="'Foto cover inserida com sucesso'" />
    </v-container>
</template>
<script>
import Work from '@/api/Work.js'
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';

export default{
    name:"Photos",
    components:{
        SuccessSnackBar
    },
    props:['work_object'],
    data(){
        return{
            success: false,
            loading: true,
            fields:{
                logo: null
            },
            error:{
                show: false,
                message: '',
                title: null
            },
            logo_changed: false,
            img_url: null,            
            rules: [
                value => !value || value.size < 2000000 || 'Image to big',
            ],  
            imageObject:{
                width: null,
                height: null
            }
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{   
        fillBaseData(){
            this.loading = false

            if(this.work_object.cover != null){
                this.img_url = this.work_object.cover
            }
        },
        loadedImage(){
            const img = new Image();
            img.src = this.$refs.logoImage.src;
            img.onload = () => {
                this.imageObject = img
                this.imageObject.width = img.width
                this.imageObject.height = img.height
            };
        },     
        onFileChange(file){
            if(file == null)
                return

            let tamanho = 2000000; // 2 MB

            if(file.size >= tamanho){
                this.fields.logo = null
                this.error.message = 'Image bigger than 2MB'
                this.error.show = true
                return
            }

            this.logo_changed = true;

            const reader = new FileReader();

            reader.onload = e => {
                this.img_url = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        async submit(){
            const formData = new FormData();
            if(this.fields.logo != null)
                formData.append('logo', this.fields.logo)
            else return;
                
            

            await Work.uploadCover(formData, this.$route.params.id).then(() => {

                //this.success = true;

                this.loading = false;

                this.$emit('reload')
                
                }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
                }

                this.error.title = 'ERROR: ' + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        }
    }
}
</script>