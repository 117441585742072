<template>
  <v-app>
    <v-main>
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col cols="12">

            <div class="d-flex justify-center pb-8">
              <v-img
              lazy-src="@/assets/gpd_logo.png"
              src="@/assets/gpd_logo.png"
              max-width="250"
              ></v-img>
            </div>

            <h1 v-if="!error" class="h1 pb-8 text-center">Confirmação de Email</h1>

            <validation-observer
              ref="email"
              v-slot="{ invalid }"
            >
              <v-form @submit.prevent="submit" v-if="!error">
                <v-row>
                  <v-col
                      cols="12"
                      md="6"
                      offset-md="3"
                    >
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <validation-provider name="Password" vid="password" rules="required" v-slot="{ errors }">
                          <v-text-field v-model="password" label="Password *" type="password" prepend-inner-icon="mdi-form-textbox-password" :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <validation-provider name="Confirmação Password" vid="password_confirmation" rules="required|password:@password" v-slot="{ errors }">
                          <v-text-field v-model="password_confirmation" label="Confirmação Password *" type="password" prepend-inner-icon="mdi-form-textbox-password" :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                    <v-btn
                      depressed
                      color="primary"
                      class="mr-4"
                      type="submit"
                      :disabled="invalid"
                      large
                      block
                    >
                      Validar Email
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-row v-else>
                <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                  <h2 class="error--text text-center">Não é possível ativar a sua conta</h2>
                  <p class="body-1 text-center mt-5">Para ativar a sua conta, entre em contacto para: <a class="text-decoration-none font-weight-bold secundary--text" :href="'mailto:' + email">{{email}}</a></p>
                  <p>Erro: {{errorText}}</p>
                  <v-row class="mt-5">
                    <v-col
                      cols="12"
                      md="6"
                      offset-md="3"
                    >
                      <v-btn
                        depressed
                        color="primary"
                        class="mr-4"
                        type="button"
                        large
                        block
                        link
                        to="/"
                      >
                        Página Inicial
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </validation-observer>
             <div class="d-flex justify-center mb-5 mt-5">
              <v-img
              lazy-src="@/assets/geonorte.png"
              src="@/assets/geonorte.png"
              max-width="150"
              ></v-img>
            </div>
            <div class="d-flex justify-center">
              <span class="body-2 mr-3">Powered By </span>
              <v-img
              lazy-src="@/assets/logo_dream.png"
              src="@/assets/logo_dream.png"
              max-width="80"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import User from "@/api/User.js"

export default {
  components: {
  },
  data: () => ({
    password: "",
    password_confirmation: "",
    error: false,
    errorText:"",
  }),
  beforeCreate(){

    document.title = "GPD - Confirmação Email";

    if(localStorage['auth'] == 1){
      this.$router.push('/admin');
    }
  },
  computed: {
    email: function(){
      return process.env.VUE_APP_EMAIL;
    }
  },
  methods:{
    submit () {
        this.$refs.email.validate().then((valid) => {
          if (!valid) {
            return;
          }

          let payload = {
            id: this.$route.params.id,
            password: this.$encryptValue(this.password),
            password_confirmation: this.$encryptValue(this.password_confirmation),
          }

          User.confirmEmail(payload)
            .then(() => {

                this.$router.push('/');

                
            }).catch(err => {

              if(err.response.status == 422) {

                this.$refs.email.setErrors(err.response.data.errors);
                
                return;
              }

              this.error = true;

              this.errorText = err.message

          });
        });
      },
  }
};
</script>
