<template>
    <v-container fluid>
       <v-progress-linear v-if="loading"
          indeterminate          
        ></v-progress-linear>
        <v-row v-if="!loading">
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            v-model="machine_model"
                            :items="getMachines"
                            item-value="id"
                            :item-text="item => item.code + ' - ' + item.name"
                            dense outlined clearable
                            label="Máquinas"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn :disabled="machine_model == null" @click="insertMachine" class="success" rounded>Inserir</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left">
                                Código
                            </th>
                            <th class="text-left">
                                Máquina
                            </th>
                            <th class="text-left">
                                Tipo
                            </th>
                            <th class="text-left">
                                Opções
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in fields.machines"
                                :key="item.code"
                            >
                                <td>{{ item.code }}</td>
                                <td>{{ item.name }}</td>
                                <td>
                                    {{item.external == true ? 'Externa' : 'Interna'}}
                                </td>
                                <td>
                                    <IconRemove
                                        class="mr-2"
                                        @on-submit="deleteMachine(item)"
                                      />
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
      <SuccessSnackBar v-model="successSnack.show" :message="successSnack.message"/>
    </v-container>
</template>
<script>
//import Work from '@/api/Work.js'
import Machine from '@/api/Machine.js'
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';
import IconRemove from '@/components/ui/IconRemove.vue';

export default{
    name:"WorkMachines",
    components:{
        SuccessSnackBar,
        IconRemove
    },
    props: {
        fields: Object
    },
    data(){
        return{
            loading: true,
            successSnack:{
                show: false,
                message: ''
            },
            machine_model: null,
            machines: []
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        deleteMachine(item){
            let i = 0; let pos = -1;
            this.fields.machines.forEach(element => {
                if(element.id == item.id)
                    pos = i 
                
                i++
            });

            if(pos != -1){
                this.fields.machines.splice(pos, 1)
            }

            /*let array_auxiliar = []
            this.fields.machines.forEach(element => {
                if(element.id != item.id)
                    array_auxiliar.push(element.id)
            });
            
            let data = {
                machines: array_auxiliar
            }
            Work.setMachines(data, this.$route.params.id).then(()   =>  {
                this.machine_model = null
                this.successSnack.message = "Máquina removida com sucesso"
                this.successSnack.show=true
                this.$emit('update')
            });*/
        },
        insertMachine(){
            let ob = null
            
            this.getMachines.forEach(element => {
                if(element.id == this.machine_model)
                    ob = element
            });

            if(ob != null)
                this.fields.machines.push(ob)

            /*let array_auxiliar = []
            this.fields.machines.forEach(element => {
                array_auxiliar.push(element.id)
            });

            array_auxiliar.push(this.machine_model)

            let data = {
                machines: array_auxiliar
            }

            
            Work.setMachines(data, this.$route.params.id).then(()   =>  {
                this.worker_model = null
                this.successSnack.message = "Máquina adicionada com sucesso"
                this.successSnack.show=true
                this.$emit('update')
            });*/
        },
        fillBaseData(){
            Machine.list().then((resp)   =>  {
               this.machines = resp.data 
               this.loading= false
            });
        }
    },
    computed:{
        getMachines(){
            let auxiliar = []

            this.machines.forEach(w => {
                let exists = false
                this.fields.machines.forEach(wa => {
                    if(wa.id == w.id)
                        exists = true
                });

                if(exists == false)
                    auxiliar.push(w)
            });

            return auxiliar
        }
    }
}
</script>