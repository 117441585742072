<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="5">
                <v-menu
                    v-model="initialDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :close-on-content-click="false"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            label="Data inicial"
                            v-model="fields.start_date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="fields.date = null"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="fields.start_date"
                    :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                    locale="pt-pt"
                    @input="initialDate = false;"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="5">
                <v-menu
                    v-model="finalDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :close-on-content-click="false"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            label="Data final"
                            v-model="fields.end_date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="fields.date = null"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="fields.end_date"
                    :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                    locale="pt-pt"
                    @input="finalDate = false;"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2">
                <v-btn depressed color="primary" @click="searchWorkerWork">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn> 
            </v-col>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="worker_work"
                    :server-items-length="worker_work.length"
                    :loading="loading"
                    :footer-props="footer"
                    :options.sync="options"
                    locale="pt-pt"
                >


                <template v-slot:item.measures="{item}">
                    <span>
                        {{item.measures[0].horas}}h
                    </span>
                </template>

                <template v-slot:item.performance="{item}">
                    <span>
                        {{item.measures[0].rendimento_uo}}%
                    </span>
                </template>

                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import WorkerApi from '@/api/Worker.js'

export default{
    name: "WorkerWork",
    props:{
        worker_id: String
    },
    data(){
        return{
            loading: true,
            initialDate: false,
            finalDate: false,
            worker_work: [],
            fields:{
                start_date: null,
                end_date: null
            },
            footer: {
                'items-per-page-options': [5, 10, 15, 20],
                'items-per-page-text': "Linhas por página"
            },
            options: {},
            headers: [
                {
                    text: 'Dia',
                    sortable: true,
                    value: 'date',
                },
                {
                    text: 'Obra',
                    sortable: true,
                    value: 'work_name',
                },
                {
                    text: 'Trabalhado (h)',
                    sortable: true,
                    value: 'measures',
                },
                {
                    text: 'Performance (%)',
                    sortable: true,
                    value: 'performance',
                },
            ]
        }
    },
    mounted(){
        this.fields.start_date = new Date().toISOString().substr(0, 10)
        let today = new Date();
        const month = today.getMonth()
        today.setMonth(month-1)
        this.fields.end_date = today.toISOString().substr(0, 10)
        this.fillBaseData()
    },
    methods:{
        searchWorkerWork(){
            this.fillBaseData()
        },
        fillBaseData(){
            let data = {
                worker_id: this.worker_id,
                start_date: this.fields.start_date,
                end_date: this.fields.end_date
            }
            WorkerApi.getWork(data).then(({data}) => { this.worker_work = data; this.loading = false;});
        }
    }
}
</script>