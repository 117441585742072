<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-bulldozer</v-icon> <span>Gestão de Máquinas</span>
      </h1>
      <v-icon color="primary">
        mdi-eye
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Visualizar

      <v-icon color="red" class="ml-4">
        mdi-delete
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Remover

      <v-icon color="success" class="ml-4">
        mdi-file-export
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Exportar ficheiro

      <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true; "
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Pesquisar</span>
            </v-tooltip>
            <v-tooltip right color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="success"
                  large
                  @click="exportingEquipment = true; equipmentToExport = null"
                  v-if="$root.session.hasPermission(['super', 'export.equipments'])"
                >
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Exportar</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  to="/admin/machines/create"
                  v-if="$root.session.hasPermission(['super', 'machines.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Registar</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16"
          locale="pt-pt"
        >
        <template v-slot:item.name="{item}">
          {{ item.abbreviation == null ? item.name : item.abbreviation }}
        </template>

        <template v-slot:item.value="{item}">
          {{ item.value }}€ /{{item.machine_unit}}
        </template>

        <template v-slot:item.status="{item}">
            <Tooltip
              v-if="item.status == 'Avaria total'"
              class="my-4 mr-4"
              color="error"
              :text="item.status"
              top
            >
              <v-icon color="error">mdi-sync-alert</v-icon>
            </Tooltip>

            <Tooltip
              v-if="item.status == 'Avaria parcial'"
              class="my-4 mr-4"
              color="warning"
              :text="item.status"
              top
            >
              <v-icon color="warning">mdi-sync-alert</v-icon>
            </Tooltip>

            <Tooltip
              v-if="item.status == 'Correção de avaria'"
              class="my-4 mr-4"
              color="blue"
              :text="item.status"
              top
            >
              <v-icon color="blue">mdi-sync-alert</v-icon>
            </Tooltip>

            <Tooltip
              v-if="item.status == 'Á ordem de obra ou com parte diária'"
              class="my-4 mr-4"
              color="success"
              :text="item.status"
              top
            >
              <v-icon color="success">mdi-hand-okay</v-icon>
            </Tooltip>
            
            
            
          
        </template>

        <template v-slot:item.external="{item}">
          {{ item.external  ? 'Externo' : 'Interno' }}
        </template>
        
          <template v-slot:item.actions="{item}">
            <IconView
              class="mr-2"
              @click="viewMachine(item)"
            />
            <IconSharePoint
              class="mr-2"
              @click="openSharepoint(item)"
              v-if="item.sharepoint_link != null"
            />
            <IconExport
              class="mr-2"
              text="Relatório por Equipamento ou Viatura"
              v-if="$root.session.hasPermission(['super', 'export.equipments'])"
              @click="equipmentToExport = item; exportingEquipment = true"
            />
            <IconRemove
              v-if="!item.has_actions && $root.session.hasPermission(['super', 'machines.delete'])"
              class="mr-2"
              @on-submit="deleteMachine(item)"
            />
          </template>
        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetMachines">
              Limpar Campos
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchMachines">
            
            <v-list-item>
              <v-text-field
                v-model="filter.code"
                prepend-inner-icon="mdi-barcode"
                label="Código"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.name"
                prepend-inner-icon="mdi-form-textbox"
                label="Nome"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-autocomplete
                v-model="filter.external"
                prepend-inner-icon="mdi-barcode"
                label="Tipo"
                :items="['Interno', 'Externo']"
                clearable
              ></v-autocomplete>
            </v-list-item>

            <v-list-item>
              <v-autocomplete
                v-model="filter.status"
                prepend-inner-icon="mdi-barcode"
                label="Estado"
                :items="['Á ordem de obra ou com parte diária', 'Correção de avaria', 'Avaria total', 'Avaria parcial']"
                clearable
              ></v-autocomplete>
            </v-list-item>

            <v-list-item>
              <v-autocomplete
                v-model="filter.machine_unit"
                prepend-inner-icon="mdi-tape-measure"
                label="Unidade"
                :items="['dia', 'mes']"
                clearable
              ></v-autocomplete>
            </v-list-item>

            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              Pesquisar
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
      <DialogSuccess :opened="started" @on-ok="started = false" >
        Exportação Iniciada, verifique as notificações para extrair o documento!
      </DialogSuccess>

      <Dialog 
        :opened="exportingEquipment">
        <template #content>
          <v-container fluid>
            <v-card-title class="mt-n8">Escolha o intervalo:</v-card-title>
            <v-row>
              <v-col cols="12" md="6">
                <v-menu
                  v-model="equipment_export_started_at"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        label="Data Inicio"
                        v-model="equipment.export.started_at"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="equipment.export.started_at = null"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="equipment.export.started_at"
                    locale="pt-pt"
                    @input="equipment_export_started_at = false;"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                  v-model="equipment_export_finished_at"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        label="Data Fim"
                        v-model="equipment.export.finished_at"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="equipment.export.finished_at = null"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="equipment.export.finished_at"
                    locale="pt-pt"
                    @input="equipment_export_finished_at = false;"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template #footer>
            <v-btn
              color="error"
              depressed
              @click="exportingEquipment = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              depressed
              @click="exportEquipment"
            >
              Exportar
            </v-btn>
        </template>
      </Dialog>
      <Loader v-if="loading"></Loader>  
  </section>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import IconExport from '@/components/ui/IconExport.vue';
import Dialog from '@/components/ui/Dialog.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Machine from "@/api/Machine.js";
import dayjs from 'dayjs';
import IconSharePoint from '@/components/ui/IconSharePoint.vue';
import Loader from '@/components/ui/Loader.vue'
import Tooltip from '@/components/ui/Tooltip.vue';

export default {
  components: {
    IconRemove,
    IconView,
    DialogSuccess,
    IconExport,
    Dialog,
    IconSharePoint,
    Loader,
    Tooltip
},
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchMachines()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "GPD - Gestão de Máquinas";
  },
  data: () => ({
    equipment_export_started_at: false,
    equipment_export_finished_at: false,
    equipment: {
      export: {
        started_at: null,
        finished_at: null,
        type: 'Excel',
        machine_id: null,
        report: '',
        unit_id: '',
        work_id: ''
      }
    },
    equipmentToExport: null,
    started: false,
    exportingEquipment: false,
    headers: [
      {
        text: 'Código',
        sortable: true,
        value: 'code',
      },
      {
        text: 'Nome',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Custo',
        sortable: true,
        value: 'value',
      },
      {
        text: 'Estado',
        sortable: true,
        value: 'status',
      },
      {
        text: 'Interno',
        sortable: true,
        value: 'external',
      },
      { 
        text: 'Opções', 
        value: 'actions', 
        sortable: false 
      },
    ],
    report: {
      started_at: null,
      finished_at: null,
    },
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      code:null,
      name: null,
      machine_unit: null,
      external: null,
      status: null
    },
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "machines.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();
  },
  methods:{
    openSharepoint(i){
      window.open(i.sharepoint_link, '_blank')
    },
    getTableDate(date) {
      return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
    },
    fillBaseData() {

      if(localStorage["machinesv2-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["machinesv2-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchMachines(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["machinesv2-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Machine.search(request).then(response => {

        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetMachines()
    {
      this.filter = {
      };

      this.searchMachines();
    },
    viewMachine(item){
      this.$router.push('/admin/machines/' + item.id);
    },
    deleteMachine(item){
      Machine.delete(item.id)
        .then(() => {
          this.searchMachines();
        });
    },
    async exportEquipment() {
      this.loading = true

      let data = {...this.equipment.export};
      

      if(this.equipmentToExport != null){
        data.machine_id = this.equipmentToExport.id; 
      }

      await Machine.exportEquipments(data)
        .then((resp) => {
          if(resp.data.success){
            if(this.equipmentToExport != null)
                this.equipmentToExport.id = null
              if(resp.data.object == null)
              {
                  this.error.message = "Não há dados para mostrar"
                  this.error.show = true
                  this.exportingDocument = false
                  return
              }else 
                  window.open(resp.data.object)
          }
        });
        this.loading = false
    },
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão Máquinas',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
