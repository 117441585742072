import BaseApi from "./BaseApi";

export default class System extends BaseApi{
    construct() {}
 
    static async getStatus(params) {
        return await System.getAxiosInstance().get(System.getApiUrl() + 'get-status', params);
    }

    static async setStatus(params) {
        return await System.getAxiosInstance().post(System.getApiUrl() + 'set-status', params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/system/";
    }
}