<template>
    <section class="mt-16">
      <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-export</v-icon> <span>Exportação de ficheiros</span>
        </h1>
    
  
        <div style="position:relative">
            <ExportList />
        </div>
    </section>
</template>
<script>
import ExportList from '@/components/files/ExportList.vue';

export default{
    components:{
        ExportList
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Exportação de ficheiros',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
}
</script>