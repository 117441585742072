<template>
    <v-container fluid class="ma-0 pa-0" style="overflow: hidden;">
        <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
        ></v-progress-linear>
        <v-card class="ma-0 pa-0" v-if="!loading">
                <v-card-title class="primary white--text">
                    Detalhes da diária
                    <v-spacer></v-spacer>
                    <v-btn icon @click="$emit('close')"><v-icon color="white">mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-card-subtitle class="mt-4"><strong>Obra: </strong>{{ diary.work_object.name }}</v-card-subtitle>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card-subtitle class="mt-4"><strong>Cliente: </strong>{{ diary.work_object.client.name }}</v-card-subtitle>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card-subtitle class="mt-4"><strong>Estado: </strong>{{ diary.work_object.status }}</v-card-subtitle>
                    </v-col>
                    <v-col cols="12">
                        <v-card-subtitle><strong>Descrição: </strong>{{ diary.work_object.description }}</v-card-subtitle>
                    </v-col>
                </v-row>
                <v-card-title>
                    Colaboradores
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Colaborador
                                    </th>
                                    <th class="text-left">
                                        Horas de Início
                                    </th>
                                    <th class="text-left">
                                        Horas de fim
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-for="worker in diary.workers" :key="worker.id">
                                <tr>
                                    <td>
                                        {{worker.code}} - {{ worker.name }}
                                    </td>
                                    <td>
                                        {{ fixTime(worker.started_hour) }}:{{ fixTime(worker.started_minute) }}
                                    </td>
                                    <td>
                                        {{ fixTime(worker.finished_hour) }}:{{ fixTime(worker.finished_minute) }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-title>
                    Máquinas
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Máquina
                                    </th>
                                    <th class="text-left">
                                        Horas de início
                                    </th>
                                    <th class="text-left">
                                        Horas de fim
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-for="machine in diary.machines" :key="machine.id">
                                <tr>
                                    <td>
                                        {{machine.code }} - {{ machine.name }}
                                    </td>
                                    <td>
                                        {{ machine.started_hours }}
                                    </td>
                                    <td>
                                        {{ machine.finished_hours }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>                
                <v-card-title>
                    Unidades de obra
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Unidade
                                    </th>
                                    <th class="text-left">
                                        Unit
                                    </th>
                                    <th class="text-left">
                                        Medição
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-for="work_unit in diary.work_units" :key="work_unit.id">
                                <tr>
                                    <td>
                                        {{work_unit.code}} - {{ work_unit.name }}
                                    </td>
                                    <td>
                                        {{ work_unit.unit }}
                                    </td>
                                    <td>
                                        {{ work_unit.measure }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import DiaryApi from "@/api/Diary.js";

export default {
    components: {
    },
    props:{
        id: String
    },
    data: () => ({
        diary: {},
        loading:true,
        reloadAllComponent: 0
    }),
    mounted(){

        if(!this.$root.session.hasPermission(["super", "diaries.read"])) {
        this.$router.push('/admin');
        }

        DiaryApi.find(this.id).then(({data}) => { this.diary = data; this.loading = false;});
    },
    methods:{
        fixTime(t){
            if(t < 10)
                return '0' + t

            return t
        }
    }
};
</script>
