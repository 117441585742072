<template>
    <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
        <v-card-title class="primary white--text">Anunciar avaria</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
            <v-row>
                <v-col cols="12">
                    <v-select
                        v-model="dataFields.state"
                        label="Nível da avaria"
                        :items="states"
                    />
                </v-col>
                <v-col cols="12">                    
                    <v-textarea
                        label="Descrição"
                        v-model="dataFields.breakdown"
                        hint="Descreva a avaria"
                    ></v-textarea>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="dataFields.breakdown == '' || dataFields.state == null" depressed :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'" @click="submit">
                Submeter avaria
            </v-btn>
        </v-card-actions>
        
        <DialogSuccess :opened="success" @on-ok="$emit('close-success')" >
          Avaria registada com sucesso
        </DialogSuccess>

    </v-card>
</template>
<script>
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default{
    name: "Breakdown",
    components:{
        DialogSuccess
    },
    props:{
        machine: Object,
        fields: Object
    },
    data(){
        return{
            states: ['Avaria total', 'Avaria parcial'],
            success: false,
            loading: true,
            dataFields:{
                breakdown: '',
                state: null
            },
            error:{
                title: null,
                show: false,
                message: ''
            }
        }
    },
    mounted(){
        this.loading = false
        console.log(this.machine)
    },
    methods:{
        submit(){
            this.loading = true;

            let data = {
                machine_id: this.machine.id,
                diary_id: this.$route.params.id,
                breakdown: this.dataFields.breakdown,
                breakdown_date: this.fields.date,
                status: this.dataFields.state,
                created_by: this.$root.session.id
            }
            this.success = true
            this.$emit('breakdown', data)
        }
    }
}
</script>